import {
  Card,
  Col,
  Descriptions,
  Divider,
  Empty,
  Progress,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from "antd";
import React from "react";
import {
  useStripeCreditGrants,
  useStripeUpcomingInvoice,
} from "../../../util/data_hooks";
import { getFormattedDate } from "../../../helpers/date_helpers";
import moment from "moment";

const formatCurrency = (number: number) =>
  number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

export function Billing() {
  const { creditGrants, isLoading: creditGrantsLoading } =
    useStripeCreditGrants();

  const { upcomingInvoice, isLoading: upcomingInvoiceLoading } =
    useStripeUpcomingInvoice();

  const isLoading = creditGrantsLoading || upcomingInvoiceLoading;

  if (isLoading) return <Skeleton active />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title="Upcoming Invoice">
          {!upcomingInvoice ? (
            <Empty description="No upcoming invoice found." />
          ) : (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Table
                  size="small"
                  dataSource={upcomingInvoice.line_items}
                  columns={[
                    {
                      title: "Description",
                      dataIndex: "description",
                      key: "description",
                    },
                    {
                      title: "Amount",
                      dataIndex: "amount",
                      key: "amount",
                      render: (amount) => formatCurrency(amount / 100),
                    },
                    {
                      title: "Period",
                      dataIndex: "period",
                      key: "period",
                      render: (period) =>
                        `${moment(period.start).format("MM/DD/YY")} - ${moment(
                          period.end
                        ).format("MM/DD/YY")}`,
                    },
                  ]}
                  pagination={false}
                />
              </Col>

              <Col span={24}>
                <Descriptions title="Totals">
                  <Descriptions.Item label="Subtotal">
                    {formatCurrency(upcomingInvoice.subtotal / 100)}
                  </Descriptions.Item>

                  {upcomingInvoice.credit_total > 0 && (
                    <Descriptions.Item label="Credits Applied">
                      -{formatCurrency(upcomingInvoice.credit_total / 100)}
                    </Descriptions.Item>
                  )}

                  <Descriptions.Item label="Amount Due">
                    {formatCurrency(upcomingInvoice.total / 100)}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          )}
        </Card>
      </Col>

      <Col span={24}>
        <Card title="Credit Grants">
          {creditGrants.length === 0 ? (
            <Empty description="No credit grants found." />
          ) : (
            <Row>
              {creditGrants.map((grant, index) => {
                const percent =
                  (grant.amount_used / grant.amount_granted) * 100;

                const remaining = 100 - percent;

                return (
                  <Col span={24} key={index}>
                    <Space>
                      <Typography.Text strong>{grant.name} </Typography.Text>
                      <Typography.Text type="secondary" strong={false}>
                        ({remaining}% Remaining)
                      </Typography.Text>
                    </Space>

                    <Progress percent={percent} showInfo={false} />

                    <Descriptions>
                      <Descriptions.Item label="Amount Granted">
                        {formatCurrency(grant.amount_granted / 100)}
                      </Descriptions.Item>

                      <Descriptions.Item label="Effective">
                        {getFormattedDate(new Date(grant.effective_at))}
                      </Descriptions.Item>

                      {grant.expires_at && (
                        <Descriptions.Item label="Expires">
                          {getFormattedDate(new Date(grant.expires_at))}
                        </Descriptions.Item>
                      )}

                      {grant.voided_at && (
                        <Descriptions.Item label="Voided">
                          {getFormattedDate(new Date(grant.voided_at))}
                        </Descriptions.Item>
                      )}
                    </Descriptions>

                    <Divider />
                  </Col>
                );
              })}
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
}
