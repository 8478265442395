import React from "react";
import { useSlackUsers } from "../../../../../util/data_hooks";
import SkeletonInput from "antd/es/skeleton/Input";
import { CRMFieldType } from "../../../../../util/enums";
import { WorkflowSelectInput } from "../../dynamic_variables/WorkflowSelectInput";
export function SlackUsersSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const { slackUsers, isLoading } = useSlackUsers();

  if (isLoading) return <SkeletonInput active style={{ width: 500 }} />;

  return (
    <WorkflowSelectInput
      multiple
      fieldType={CRMFieldType.Id}
      placeholder="Select User"
      variableDefinitionFilter={(variableDefinitions) => {
        return variableDefinitions.filter((variableDefinition) =>
          variableDefinition.key.includes("slack_user_id")
        );
      }}
      options={slackUsers.map(({ user_id, username, real_name }) => {
        return {
          label: `${real_name} (${username})`,
          value: user_id,
        };
      })}
      value={value}
      onChange={onChange}
    />
  );
}
