import { InputNumber, Select, Space, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { parseVariable } from "../util/helpers";

enum RelativeDateType {
  BeginningOfQuarter = "beginning_of_quarter",
  EndOfQuarter = "end_of_quarter",
  DaysFromNow = "days_from_now",
  HoursFromNow = "hours_from_now",
  Now = "now",
}

interface DynamicVariableDateTimeProps {
  value: string;
  onChange: (value: string) => void;
}

export function DynamicVariableDateTime({
  value,
  onChange,
}: DynamicVariableDateTimeProps) {
  const {
    attributes: [parsedRelativeDateType, parsedDurationType],
  } = parseVariable(value);

  const [relativeDateType, setRelativeDateType] = useState<RelativeDateType>(
    parsedRelativeDateType as RelativeDateType
  );

  const [durationCount, setDurationCount] = useState<number>(
    parsedDurationType ? parseInt(parsedDurationType) : null
  );

  const showDurationCount = [
    RelativeDateType.DaysFromNow,
    RelativeDateType.HoursFromNow,
  ].includes(relativeDateType);

  useEffect(() => {
    if (!relativeDateType) return;

    const attributes: string[] = ["datetime", relativeDateType];
    if (durationCount) attributes.push(durationCount.toString());
    onChange(`{{${attributes.join(".")}}}`);
  }, [relativeDateType, durationCount]);

  return (
    <Space.Compact style={{ display: "flex" }}>
      {showDurationCount && (
        <InputNumber
          value={durationCount}
          onChange={(value) => setDurationCount(value || 1)}
        />
      )}

      <Tooltip
        title={
          [
            RelativeDateType.DaysFromNow,
            RelativeDateType.HoursFromNow,
            RelativeDateType.Now,
          ].includes(relativeDateType)
            ? `"Now" is relative to when the workflow was triggered`
            : null
        }
      >
        <Select
          placeholder="Select a relative date type"
          popupMatchSelectWidth={false}
          value={relativeDateType}
          onChange={(value: RelativeDateType) => {
            const showDurationCount = [
              RelativeDateType.DaysFromNow,
              RelativeDateType.HoursFromNow,
            ].includes(value);

            setDurationCount(showDurationCount ? 5 : null);
            setRelativeDateType(value);
          }}
          options={[
            {
              label: "Beginning of Quarter",
              value: RelativeDateType.BeginningOfQuarter,
            },
            {
              label: "End of Quarter",
              value: RelativeDateType.EndOfQuarter,
            },
            {
              label: "Days from Now",
              value: RelativeDateType.DaysFromNow,
            },
            {
              label: "Hours from Now",
              value: RelativeDateType.HoursFromNow,
            },
            {
              label: "Now",
              value: RelativeDateType.Now,
            },
          ]}
        />
      </Tooltip>
    </Space.Compact>
  );
}
