import { GenerationModel } from "./enums";

export const GenerationModelDropdownOptions = [
  {
    label: "OpenAI",
    title: "OpenAI",
    options: [
      {
        label: "GPT-4o-mini",
        value: GenerationModel.Gpt4oMini,
        internetModel: false,
      },
      { label: "GPT-4o", value: GenerationModel.Gpt4o, internetModel: false },
      {
        label: "O3-mini",
        value: GenerationModel.O3Mini,
        internetModel: false,
      },
    ],
  },
  {
    label: "Anthropic",
    title: "Anthropic",
    options: [
      { label: "Haiku", value: GenerationModel.Haiku, internetModel: false },
      { label: "Opus", value: GenerationModel.Opus, internetModel: false },
      { label: "Sonnet", value: GenerationModel.Sonnet, internetModel: false },
    ],
  },
  {
    label: "Perplexity",
    title: "Perplexity",
    options: [
      { label: "Sonar", value: GenerationModel.Sonar, internetModel: true },
      {
        label: "Sonar Reasoning",
        value: GenerationModel.SonarReasoning,
        internetModel: true,
      },
      {
        label: "Sonar Pro",
        value: GenerationModel.SonarPro,
        internetModel: true,
      },
    ],
  },
];
