import { Button, Input, InputNumber, Select } from "antd";
import React, { useEffect } from "react";
import QueryBuilder, {
  Field,
  FieldSelectorProps,
  ValueEditorProps,
  formatQuery,
} from "react-querybuilder";
import { CloseCircleOutlined } from "@ant-design/icons";
import "react-querybuilder/dist/query-builder.css";
import { ConditionalOperator, CRMFieldType } from "../../../../../util/enums";
import { useWorkflowVariableDefinition } from "../../util/useWorkflowVariableDefinition";
import { DynamicVariableSelect } from "../../dynamic_variables/DynamicVariableSelect";
import SkeletonInput from "antd/es/skeleton/Input";
import { getFriendlyOperatorLabel } from "../../../../../helpers/label_maps";
import { DynamicVariableDateTime } from "../../dynamic_variables/DynamicVariableDateTime";

function FieldSelector({ options, value, handleOnChange }: FieldSelectorProps) {
  if (options.length > 0) {
    return (
      <Select
        showSearch
        value={value}
        onChange={handleOnChange}
        options={options.map((option) => {
          return { label: option.label, value: option.name };
        })}
      />
    );
  } else {
    return (
      <DynamicVariableSelect
        containerId="workflow-query-editor-field-selector"
        value={value}
        onChange={handleOnChange}
      />
    );
  }
}

function ValueInput({
  field,
  value,
  operator,
  handleOnChange,
}: ValueEditorProps) {
  const { variableDefinition, isLoading } =
    useWorkflowVariableDefinition(field);

  const defaultLeftOperands = { exists: null, not_exists: null };

  useEffect(() => {
    handleOnChange(defaultLeftOperands[operator]);
  }, [operator]);

  if (Object.keys(defaultLeftOperands).includes(operator)) return null;
  if (isLoading) return <SkeletonInput active />;

  switch (variableDefinition?.type) {
    case CRMFieldType.Reference:
    case CRMFieldType.Picklist:
    case CRMFieldType.Multipicklist:
      return (
        <Select
          value={value}
          optionFilterProp="label"
          placeholder="Select an option..."
          mode={
            variableDefinition.type === CRMFieldType.Multipicklist ||
            [
              ConditionalOperator.Includes,
              ConditionalOperator.NotIncludes,
            ].includes(operator as ConditionalOperator)
              ? "multiple"
              : null
          }
          showSearch
          onChange={handleOnChange}
          popupMatchSelectWidth={false}
          options={variableDefinition.picklist_options}
        />
      );
    case CRMFieldType.Boolean:
      return (
        <Select
          id="workflow-query-editor-boolean-value"
          value={value}
          optionFilterProp="label"
          placeholder="Select True or False"
          showSearch
          onChange={handleOnChange}
          popupMatchSelectWidth={false}
          options={[
            { label: "True", value: true },
            { label: "False", value: false },
          ]}
        />
      );
    case CRMFieldType.Currency:
    case CRMFieldType.Double:
    case CRMFieldType.Percent:
      return (
        <InputNumber
          style={{ minWidth: "20%" }}
          placeholder="Enter a number"
          step={1}
          changeOnWheel={false}
          value={value}
          onChange={handleOnChange}
        />
      );
    case CRMFieldType.Date:
    case CRMFieldType.Time:
    case CRMFieldType.DateTime:
      return (
        <DynamicVariableDateTime value={value} onChange={handleOnChange} />
      );
    default:
      return (
        <Input
          placeholder="Enter a value"
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
        />
      );
  }
}

interface WorkflowQueryEditorProps {
  fields: Field[];
  defaultQuery: any;
  onChange: (query: any) => void;
}

export function WorkflowQueryEditor({
  fields,
  defaultQuery,
  onChange,
}: WorkflowQueryEditorProps) {
  return (
    <QueryBuilder
      defaultQuery={defaultQuery}
      fields={fields}
      onQueryChange={(query) =>
        onChange(JSON.parse(formatQuery(query, "json_without_ids")))
      }
      operators={Object.values(ConditionalOperator).map((operator) => {
        return {
          name: operator,
          label: getFriendlyOperatorLabel(operator),
        };
      })}
      controlElements={{
        fieldSelector: FieldSelector,
        operatorSelector: ({ options, value, handleOnChange }) => (
          <Select
            id="workflow-query-editor-operator-selector"
            showSearch
            value={value}
            onChange={handleOnChange}
            options={options.map((option) => {
              return { label: option.label, value: option.name };
            })}
          />
        ),
        valueEditor: ValueInput,
        combinatorSelector: ({ options, value, handleOnChange }) => (
          <Select
            style={{ width: 100 }}
            value={value}
            onChange={handleOnChange}
            options={options.map((option) => {
              return { label: option.label, value: option.name };
            })}
          />
        ),
        addRuleAction: ({ handleOnClick }) => (
          <Button type="primary" onClick={handleOnClick}>
            Add Rule
          </Button>
        ),
        removeRuleAction: ({ handleOnClick }) => (
          <Button danger onClick={handleOnClick} icon={<CloseCircleOutlined />}>
            Remove
          </Button>
        ),
        addGroupAction: ({ handleOnClick }) => (
          <Button onClick={handleOnClick}>Add Rule Group</Button>
        ),
        removeGroupAction: ({ handleOnClick }) => (
          <Button danger onClick={handleOnClick} icon={<CloseCircleOutlined />}>
            Remove Rule Group
          </Button>
        ),
      }}
    />
  );
}
