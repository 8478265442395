import { useEffect } from "react";
import { useTeams } from "../../../../util/data_hooks";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import { setTeamFilterUuids } from "../../../redux/features/WorkflowFilters/workflowFilterSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { teamColors } from "../../../../helpers/teamColorHelper";

const parseTeamUuidsString = (teamUuids: string | null): string[] => {
  return teamUuids ? teamUuids.split(",") : [];
};

export function useWorkflowFilters() {
  const dispatch = useAppDispatch();
  const teamsHook = useTeams();
  const { teams } = teamsHook;
  const assignedTeamColors = teamColors(teamsHook?.teams || []);
  const teamOptions = teams.map((team) => ({
    label: team.name,
    value: team.uuid,
    color: assignedTeamColors[team.uuid],
  }));
  const teamMap = teams.reduce((acc, team) => {
    acc[team.uuid] = {
      team,
      color: assignedTeamColors[team.uuid],
    };
    return acc;
  }, {});
  const [teamFilterValue, setTeamFilterValue] = useSearchParamsState(
    "team_filter",
    null
  );
  const teamFilterUuids = useAppSelector(
    (state) => state.workflowFilter.teamFilterUuids
  );
  const dispatchTeamFilterUuids = (teamUuids: string[]) => {
    dispatch(setTeamFilterUuids(teamUuids));
  };

  useEffect(() => {
    if (teamFilterValue) {
      dispatchTeamFilterUuids(parseTeamUuidsString(teamFilterValue));
    }
  }, []);

  useEffect(() => {
    if (teamFilterUuids.length === 0) {
      setTeamFilterValue(null);
    } else {
      setTeamFilterValue(teamFilterUuids.join(","));
    }
  }, [teamFilterUuids]);

  return {
    teamFilterUuids,
    dispatchTeamFilterUuids,
    teamOptions,
    assignedTeamColors,
    teamMap,
    ...teamsHook,
  };
}
