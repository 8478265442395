import React, { useState } from "react";
import { Button, Col, InputNumber, Row, Tooltip } from "antd";
import { CodeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { DynamicVariableModal } from "./DynamicVariableModal";
import { getFriendlyVariableName } from "../util/helpers";
import { VariableDefinition } from "../../../../util/types";
import { CRMFieldType } from "../../../../util/enums";
import { DynamicVariableTag } from "./DynamicVariableTag";

interface WorkflowNumberInputProps {
  value: string | number;
  fieldType?: CRMFieldType;
  variableDefinitionFilter?: (
    variableDefinitions: VariableDefinition[]
  ) => VariableDefinition[];
  onChange: (value: string | number) => void;
}

export function WorkflowNumberInput({
  value,
  fieldType,
  variableDefinitionFilter,
  onChange,
}: WorkflowNumberInputProps) {
  const [variableModalOpen, setVariableModalOpen] = useState<boolean>(false);

  const variableName = getFriendlyVariableName(value as string);

  return (
    <>
      <DynamicVariableModal
        value={value as string}
        fieldType={fieldType}
        allowFormat={false}
        open={variableModalOpen}
        setOpen={setVariableModalOpen}
        variableDefinitionFilter={variableDefinitionFilter}
        onInsert={(newValue) => {
          onChange(newValue);
          setVariableModalOpen(false);
        }}
      />

      <Row gutter={[8, 8]} align="middle">
        <Col span={variableName ? 20 : 22}>
          {variableName ? (
            <DynamicVariableTag variable={value as string} />
          ) : (
            <InputNumber
              placeholder="Enter a number"
              style={{ width: "100%" }}
              value={value as number}
              onChange={(value) => onChange(value)}
            />
          )}
        </Col>

        {variableName && (
          <Col span={2}>
            <Tooltip title="Remove variable">
              <Button
                style={{ width: "100%", padding: 5 }}
                onClick={() => onChange(null)}
                icon={<CloseCircleOutlined />}
              />
            </Tooltip>
          </Col>
        )}

        <Col span={2}>
          <Tooltip title="Insert a dynamic variable">
            <Button
              onClick={() => setVariableModalOpen(true)}
              style={{ width: "100%", padding: 5 }}
              icon={<CodeOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}
