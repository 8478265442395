import React, { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Alert, Layout as AntdLayout, Menu, Typography } from "antd";
import { fetchSignOut, stopImpersonatingUser } from "../util/api";
import { AppContext } from "./App";
import logo from "../../assets/images/logo.png";
import smallLogo from "../../assets/images/logo-small.png";
import { useUser } from "../util/data_hooks";
import {
  UnorderedListOutlined,
  LogoutOutlined,
  ApiOutlined,
  TeamOutlined,
  ControlOutlined,
  PartitionOutlined,
  IdcardOutlined,
  LockOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
import { UserRole } from "../util/enums";
import { useLocalStorage } from "@uidotdev/usehooks";
import { MenuItemType } from "antd/es/menu/interface";
import { useFlags } from "launchdarkly-react-client-sdk";

function useMenuItems(): {
  topMenuItems: MenuItemType[];
  bottomMenuItems: MenuItemType[];
} {
  const context = useContext(AppContext);
  const { user, isLoading: isUserLoading } = useUser();
  const { credits } = useFlags();
  const navigate = useNavigate();

  if (isUserLoading) return { topMenuItems: [], bottomMenuItems: [] };

  const isImpersonating = !!context.user_impersonation_email;
  const roles = user.roles;

  const topMenuItems: MenuItemType[] = [];

  if (roles.includes(UserRole.User) && !isImpersonating) {
    topMenuItems.push({
      key: MenuItemKey.Meetings,
      label: "My Meetings",
      icon: <UnorderedListOutlined />,
      onClick: () => navigate("meetings"),
    });
  }

  if (roles.includes(UserRole.Collaborator) || isImpersonating) {
    topMenuItems.push({
      key: MenuItemKey.TeamMeetings,
      label: "Team Meetings",
      icon: <TeamOutlined />,
      onClick: () => navigate("team-meetings"),
    });
  }

  const bottomMenuItems: any[] = [];

  if (roles.includes(UserRole.Admin) || isImpersonating) {
    bottomMenuItems.push({
      key: MenuItemKey.Organization,
      label: "Organization",
      icon: <IdcardOutlined />,
      onClick: () => navigate(`/dashboard/settings/organization`),
    });

    bottomMenuItems.push({
      key: MenuItemKey.Teams,
      label: "Teams",
      icon: <TeamOutlined />,
      onClick: () => navigate(`/dashboard/settings/teams`),
    });
  }

  if (roles.includes(UserRole.TechnicalAdmin) || isImpersonating) {
    bottomMenuItems.push({
      key: MenuItemKey.FieldConfiguration,
      label: "Field Configurations",
      icon: <ControlOutlined />,
      onClick: () => navigate(`/dashboard/settings/field-configurations`),
    });

    bottomMenuItems.push({
      key: MenuItemKey.Workflows,
      label: "Workflows",
      icon: <PartitionOutlined />,
      onClick: () => navigate(`/dashboard/settings/workflows`),
    });
  }

  bottomMenuItems.push({
    key: MenuItemKey.Integrations,
    label: "Integrations",
    icon: <ApiOutlined />,
    onClick: () => navigate("integrations"),
  });

  if (credits && roles.includes(UserRole.Admin)) {
    bottomMenuItems.push({
      key: MenuItemKey.Billing,
      label: "Usage & Billing",
      icon: <BarChartOutlined />,
      onClick: () => navigate("usage-and-billing"),
    });
  }

  if (roles.includes(UserRole.SuperAdmin) || isImpersonating) {
    bottomMenuItems.push({
      key: MenuItemKey.Settings,
      label: "Employee",
      icon: <LockOutlined />,
      children: [
        {
          key: MenuItemKey.AllOrganizations,
          label: "All Organizations",
          onClick: () => navigate(`/dashboard/settings/all-organizations`),
        },
        {
          key: MenuItemKey.Products,
          label: "Products",
          onClick: () => navigate(`/dashboard/settings/products`),
        },
      ],
    });
  }

  bottomMenuItems.push({
    key: "logout",
    label: "Logout",
    icon: <LogoutOutlined />,
    onClick: async () => {
      await fetchSignOut(context.authenticity_token);
      window.location.reload();
    },
  });

  return { topMenuItems, bottomMenuItems };
}

enum MenuItemKey {
  Meetings = "meetings",
  TeamMeetings = "team-meetings",
  Integrations = "integrations",
  Billing = "usage-and-billing",
  Settings = "settings",
  Organization = "organization",
  FieldConfiguration = "field-configuration",
  Workflows = "workflows",
  AllOrganizations = "all-organizations",
  Products = "products",
  Teams = "teams",
}

const SIDER_WIDTH = 220;

export default function Layout() {
  const navigate = useNavigate();
  const { isLoading: isUserLoading } = useUser();
  const context = useContext(AppContext);

  const [sidebarCollapsed, setSidebarCollapsed] = useLocalStorage<boolean>(
    "sidebar-collapsed",
    false
  );

  const [selectedKey, setSelectedKey] = useState<MenuItemKey>(null);

  useEffect(() => {
    if (isUserLoading) return;

    const currentSelectedKey = Object.values(MenuItemKey).find((item) => {
      const isOnSettingsPage = location.pathname.includes(
        "/dashboard/settings"
      );

      return location.pathname.includes(
        isOnSettingsPage ? `/dashboard/settings/${item}` : `/dashboard/${item}`
      );
    });

    setSelectedKey(currentSelectedKey);
  }, [isUserLoading, location.pathname]);

  const isImpersonating = !!context.user_impersonation_email;

  useEffect(() => {
    if (!isImpersonating) return;

    console.log(
      `ℹ️ Currently impersonating ${context.user_impersonation_email}`
    );
  }, [isImpersonating, location.pathname]);

  const { topMenuItems, bottomMenuItems } = useMenuItems();

  return (
    <AntdLayout style={{ minHeight: "100vh", minWidth: 1300 }}>
      <AntdLayout.Sider
        collapsible
        collapsed={sidebarCollapsed}
        onCollapse={(value) => setSidebarCollapsed(value)}
        width={SIDER_WIDTH}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            height: "100%",
            paddingBottom: 25,
          }}
        >
          <div style={{ marginTop: 15 }}>
            <img
              src={sidebarCollapsed ? smallLogo : logo}
              sizes="small"
              style={{
                width: sidebarCollapsed ? 45 : 125,
                cursor: "pointer",
                margin: sidebarCollapsed
                  ? "5px 0px 20px 20px"
                  : "10px 0px 20px 20px",
              }}
              onClick={() => navigate("/dashboard")}
            />

            <Menu
              selectedKeys={[selectedKey]}
              items={topMenuItems}
              theme="dark"
              onClick={({ key }) => setSelectedKey(key as MenuItemKey)}
            />
          </div>

          <Menu
            selectedKeys={[selectedKey]}
            items={bottomMenuItems}
            theme="dark"
            onClick={({ key }) => setSelectedKey(key as MenuItemKey)}
          />
        </div>
      </AntdLayout.Sider>
      <AntdLayout>
        <AntdLayout.Content
          style={{ position: "relative", margin: "1rem 2rem" }}
        >
          {context.user_impersonation_email && (
            <Alert
              showIcon
              message={
                <Typography.Text>
                  Viewing instance for{" "}
                  <Typography.Text strong>
                    {context.user_impersonation_email.split("@").pop()}.
                  </Typography.Text>{" "}
                  <Typography.Link
                    onClick={async () => {
                      await stopImpersonatingUser();
                      window.location.href =
                        "/dashboard/settings/all-organizations";
                    }}
                  >
                    Stop Viewing
                  </Typography.Link>
                </Typography.Text>
              }
              type="warning"
              style={{ marginBottom: 15 }}
            />
          )}
          <Outlet />
        </AntdLayout.Content>
      </AntdLayout>
    </AntdLayout>
  );
}
