import React from "react";
import { Popover, Space, Spin, Typography } from "antd";
import { Descriptions } from "antd";
import { useWorkflowVariableDefinition } from "../util/useWorkflowVariableDefinition";
import { getFormatDescription, getFriendlyVariableName } from "../util/helpers";
import { blue, red, gold } from "@ant-design/colors";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
export const generateColor = (string: string): string => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";

  let charIndexSum = 0;

  string.split("").forEach((char) => {
    charIndexSum += alphabet.indexOf(char.toLowerCase());
  });

  const colors = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];

  const index = charIndexSum % colors.length;

  return colors[index];
};

export function DynamicVariableTag({
  variable,
  children,
}: {
  variable: string;
  children?: React.ReactNode;
}) {
  const formatDescription = getFormatDescription(variable);

  const { variableDefinition, isLoading } =
    useWorkflowVariableDefinition(variable);

  return (
    <Popover
      content={
        <>
          {isLoading ? (
            <Spin size="small" />
          ) : !variableDefinition ? (
            <Space>
              <ExclamationCircleTwoTone twoToneColor={gold[5]} />
              <Typography.Text>Variable not found</Typography.Text>
            </Space>
          ) : (
            <Descriptions
              colon={false}
              size="small"
              style={{ maxWidth: 400 }}
              layout="vertical"
              column={1}
            >
              {variableDefinition && (
                <Descriptions.Item label="Variable Description">
                  {variableDefinition.description}
                </Descriptions.Item>
              )}

              {formatDescription && (
                <Descriptions.Item label="Format">
                  {formatDescription}
                </Descriptions.Item>
              )}
            </Descriptions>
          )}
        </>
      }
    >
      <Typography.Text
        strong
        style={{
          cursor: "pointer",
          backgroundColor: !variableDefinition && !isLoading ? red[0] : blue[0],
          padding: 3,
          margin: "0px 1px",
          borderRadius: 2,
        }}
      >
        {children || `{{${getFriendlyVariableName(variable)}}}`}
      </Typography.Text>
    </Popover>
  );
}
