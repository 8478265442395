import * as React from "react";
import { RouterProvider } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { SWRConfig } from "swr";
import { swrError } from "../util/data_hooks";
import { getRouter } from "../util/getRouter";
import { UserRole } from "../util/enums";

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 30 }} spin />);

Sentry.init({
  dsn: "https://c42069b5be8e4dfa91c034f936127333@o4505123546333184.ingest.sentry.io/4505449544482816",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["app.swyftai.com"],
    }),
    new Sentry.Replay(),
  ],
  environment: import.meta.env.MODE,
  // Performance Monitoring
  tracesSampleRate: 0.25, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled:
    import.meta.env.MODE === "staging" || import.meta.env.MODE === "production",
});

export interface AppProps {
  environment: string;
  user_roles: UserRole[];
  user_impersonation_email: string | null;
  is_user_onboarded: boolean;
  authenticity_token: string;
  zoom_authorization_endpoint: string;
  zoom_admin_authorization_endpoint: string;
  gong_authorization_endpoint: string;
  microsoft_authorization_endpoint: string;
  google_authorization_endpoint: string;
  outreach_authorization_endpoint: string;
  slack_authorization_endpoint: string;
  salesloft_authorization_endpoint: string;
}

export const AppContext = React.createContext<AppProps>({
  environment: import.meta.env.MODE,
  user_roles: [],
  user_impersonation_email: null,
  is_user_onboarded: false,
  authenticity_token: null,
  zoom_authorization_endpoint: null,
  zoom_admin_authorization_endpoint: null,
  gong_authorization_endpoint: null,
  microsoft_authorization_endpoint: null,
  google_authorization_endpoint: null,
  slack_authorization_endpoint: null,
  salesloft_authorization_endpoint: null,
  outreach_authorization_endpoint: null,
});

export default function App(app: AppProps) {
  const router = getRouter(app);

  return (
    <AppContext.Provider value={app}>
      <SWRConfig
        value={{
          onError: (error: swrError) => {
            if (error.status === 401) window.location.reload();
          },
        }}
      >
        <RouterProvider router={router} />
      </SWRConfig>
    </AppContext.Provider>
  );
}
