export enum CRMFieldType {
  Id = "id",
  String = "string",
  EncryptedString = "encryptedstring",
  DateTime = "datetime",
  Date = "date",
  Time = "time",
  Currency = "currency",
  Double = "double",
  Percent = "percent",
  Boolean = "boolean",
  Email = "email",
  Phone = "phone",
  Picklist = "picklist",
  Multipicklist = "multipicklist",
  Url = "url",
  Textarea = "textarea",
  HTML = "html",
  Base64 = "base64",
  Reference = "reference",
}

export enum AutomatedCRMFieldType {
  String = CRMFieldType.String,
  EncryptedString = CRMFieldType.EncryptedString,
  Currency = CRMFieldType.Currency,
  Double = CRMFieldType.Double,
  Percent = CRMFieldType.Percent,
  Boolean = CRMFieldType.Boolean,
  Picklist = CRMFieldType.Picklist,
  Multipicklist = CRMFieldType.Multipicklist,
}

export enum CRMFieldTypeLabel {
  String = "String",
  EncryptedString = "Encrypted String",
  DateTime = "Datetime",
  Date = "Date",
  Time = "Time",
  Currency = "Currency",
  Double = "Number",
  Percent = "Percent",
  Boolean = "Boolean",
  Email = "Email",
  Phone = "Phone",
  Picklist = "Picklist",
  Multipicklist = "Multi Picklist",
  Url = "Url",
  Textarea = "Textarea",
  HTML = "Rich Textarea",
  Id = "ID",
  Reference = "Reference",
  Base64 = "Base64",
}

export enum GenerationModel {
  Gpt4o = "gpt-4o",
  Gpt4oMini = "gpt-4o-mini",
  O3Mini = "o3-mini",
  Haiku = "haiku",
  Sonnet = "sonnet",
  Opus = "opus",
  Sonar = "sonar",
  SonarReasoning = "sonar-reasoning",
  SonarPro = "sonar-pro",
}

export enum Integration {
  Zoom = "zoom",
  ZoomAdmin = "zoom_admin",
  Slack = "slack",
  Gong = "gong",
  Avoma = "avoma",
  Microsoft = "microsoft",
  Google = "google",
  Salesloft = "salesloft",
  Salesforce = "salesforce",
  Hubspot = "hubspot",
  Chorus = "chorus",
  Swyft = "swyft",
  Outreach = "outreach",
}

export enum IntegrationLevel {
  User = "user",
  Organization = "organization",
}

export enum IntegrationType {
  CRM = "crm",
  Notification = "notification",
  WebConference = "web_conference",
  PhoneCall = "phone_call",
}

export enum WorkflowActionType {
  Trigger = "WorkflowActions::TriggerAction",
  UpdateCRMRecord = "WorkflowActions::UpdateCrmRecordAction",
  CreateCRMRecord = "WorkflowActions::CreateCrmRecordAction",
  GenerateContent = "WorkflowActions::GenerateContentAction",
  CreateSlackChannel = "WorkflowActions::CreateSlackChannelAction",
  SendSlackMessage = "WorkflowActions::SendSlackMessageAction",
  SendTeamsMessage = "WorkflowActions::SendTeamsMessageAction",
}

export enum WorkflowTriggerType {
  MeetingSaved = "WorkflowTriggers::MeetingSavedTrigger",
  MeetingAnalyzed = "WorkflowTriggers::MeetingAnalyzedTrigger",
  MeetingCompleted = "WorkflowTriggers::MeetingCompletedTrigger",
  MessageCreated = "WorkflowTriggers::MessageCreatedTrigger",
  CRMRecordUpdated = "WorkflowTriggers::CrmRecordUpdatedTrigger",
  CRMRecordCreated = "WorkflowTriggers::CrmRecordCreatedTrigger",
}

export enum WorkflowStatus {
  Triggered = "triggered",
  InProgress = "in_progress",
  Complete = "complete",
  Error = "error",
}

export enum ConditionalOperator {
  Exists = "exists",
  NotExists = "not_exists",
  Equals = "equals",
  NotEquals = "not_equals",
  Includes = "includes",
  NotIncludes = "not_includes",
  LessThan = "less_than",
  GreaterThan = "greater_than",
  LessThanEqualTo = "less_than_equal_to",
  GreaterThanEqualTo = "greater_than_equal_to",
}

export enum UserRole {
  SuperAdmin = "super_admin",
  User = "user",
  Collaborator = "collaborator",
  Admin = "admin",
  TechnicalAdmin = "technical_admin",
}

export enum FieldConfigurationAppendType {
  Top = "top",
  Bottom = "bottom",
  Overwrite = "overwrite",
  Smart = "smart",
  Merge = "merge",
}

export enum StandardFieldNames {
  Summary = "Summary__sw",
}

export enum MeetingType {
  WebConference = "web_conference",
  OutboundCall = "outbound_call",
  InboundCall = "inbound_call",
}

export enum MeetingStatusType {
  Analyzing = "analyzing",
  Unreviewed = "unreviewed",
  Viewed = "viewed",
  Synced = "synced",
  AutoSynced = "auto_synced",
}

export enum TeamDrawerTab {
  Edit = "edit",
  Users = "users",
  Layout = "layout",
}

export enum MessageDestinationType {
  ExistingChannel = "existing_channel",
  MessageThread = "message_thread",
  DirectMessage = "direct_message",
}

export enum PlaygroundTestType {
  Single = "single",
  Library = "library",
}

export enum GenerationModelType {
  Basic = "basic",
  Premium = "premium",
  Reasoning = "reasoning",
  BasicWebSearch = "basic_web_search",
}
