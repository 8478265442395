import React from "react";
import { Col, Row, Typography, Tabs } from "antd";
import { Usage } from "./Usage";
import { Billing } from "./Billing";
import { usePageTitle } from "../../hooks/usePageTitle";

export default function UsageAndBilling() {
  usePageTitle("Usage & Billing");

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Typography.Title level={3}>Usage & Billing</Typography.Title>

        <Typography.Text>
          View your usage and billing information.
        </Typography.Text>
      </Col>

      <Col span={24}>
        <Tabs
          items={[
            {
              label: "Usage",
              key: "usage",
              children: <Usage />,
            },
            {
              label: "Billing",
              key: "billing",
              children: <Billing />,
            },
          ]}
        />
      </Col>
    </Row>
  );
}
