import {
  ConditionalOperator,
  CRMFieldType,
  CRMFieldTypeLabel,
  FieldConfigurationAppendType,
  GenerationModelType,
  Integration,
  MeetingStatusType,
  MeetingType,
  UserRole,
  WorkflowActionType,
  WorkflowStatus,
  WorkflowTriggerType,
} from "../util/enums";

export const getFriendlyWorkflowStatusLabel = (
  workflowStatus: WorkflowStatus
): string => {
  const labels: { [key in WorkflowStatus]: string } = {
    [WorkflowStatus.Triggered]: "Triggered",
    [WorkflowStatus.InProgress]: "In Progress",
    [WorkflowStatus.Complete]: "Workflow Complete",
    [WorkflowStatus.Error]: "Error",
  };

  return labels[workflowStatus];
};

export const getFriendlyMeetingStatusLabel = (
  meetingStatus: MeetingStatusType
): string => {
  const labels: { [key in MeetingStatusType]: string } = {
    [MeetingStatusType.Analyzing]: "Analyzing",
    [MeetingStatusType.Viewed]: "Viewed",
    [MeetingStatusType.Unreviewed]: "Unreviewed",
    [MeetingStatusType.Synced]: "Manually Synced to CRM",
    [MeetingStatusType.AutoSynced]: "Auto-Synced to CRM",
  };

  return labels[meetingStatus];
};

export const getFriendlyWorkflowTriggerLabel = (
  workflowTrigger: WorkflowTriggerType
): string => {
  const labels: { [key in WorkflowTriggerType]: string } = {
    [WorkflowTriggerType.MeetingAnalyzed]: "Meeting Analyzed",
    [WorkflowTriggerType.MeetingCompleted]: "Call Completed",
    [WorkflowTriggerType.MessageCreated]: "New Message",
    [WorkflowTriggerType.MeetingSaved]: "Meeting Saved",
    [WorkflowTriggerType.CRMRecordUpdated]: "CRM Record Updated",
    [WorkflowTriggerType.CRMRecordCreated]: "CRM Record Created",
  };

  return labels[workflowTrigger];
};

export const getFriendlyWorkflowActionLabel = (
  actionType: WorkflowActionType
): string => {
  const labels: { [key in WorkflowActionType]: string } = {
    [WorkflowActionType.Trigger]: "Workflow Triggered",
    [WorkflowActionType.UpdateCRMRecord]: "Update CRM Record",
    [WorkflowActionType.CreateCRMRecord]: "Create CRM Record",
    [WorkflowActionType.GenerateContent]: "Generate Content",
    [WorkflowActionType.CreateSlackChannel]: "Create Slack Channel",
    [WorkflowActionType.SendSlackMessage]: "Send Slack Message",
    [WorkflowActionType.SendTeamsMessage]: "Send Teams Message",
  };

  return labels[actionType];
};

export const getFriendlyOperatorLabel = (
  operator: ConditionalOperator
): string => {
  const labels: { [key in ConditionalOperator]: string } = {
    [ConditionalOperator.Exists]: "Exists",
    [ConditionalOperator.NotExists]: "Does not exist",
    [ConditionalOperator.Equals]: "Is equal to",
    [ConditionalOperator.NotEquals]: "Is not equal to",
    [ConditionalOperator.Includes]: "Includes or is one of",
    [ConditionalOperator.NotIncludes]: "Does not include and is not one of",
    [ConditionalOperator.GreaterThan]: "Is greater than",
    [ConditionalOperator.LessThan]: "Is less than",
    [ConditionalOperator.GreaterThanEqualTo]: "Is greater than or equal to",
    [ConditionalOperator.LessThanEqualTo]: "Is less than or equal to",
  };

  return labels[operator];
};

export const getFriendlyIntegrationLabel = (
  integration: Integration
): string => {
  const labels: { [key in Integration]: string } = {
    [Integration.Gong]: "Gong",
    [Integration.Avoma]: "Avoma",
    [Integration.Chorus]: "Chorus",
    [Integration.Zoom]: "Zoom",
    [Integration.ZoomAdmin]: "Zoom (Admin Managed)",
    [Integration.Microsoft]: "Microsoft Teams",
    [Integration.Google]: "Google",
    [Integration.Outreach]: "Outreach",
    [Integration.Slack]: "Slack",
    [Integration.Salesforce]: "Salesforce",
    [Integration.Hubspot]: "HubSpot",
    [Integration.Salesloft]: "Salesloft",
    [Integration.Swyft]: "Swyft",
  };

  return labels[integration];
};

export const getFriendlyUserRoleLabel = (role: UserRole): string => {
  const labels: { [key in UserRole]: string } = {
    [UserRole.SuperAdmin]: "Super Admin",
    [UserRole.Collaborator]: "Collaborator",
    [UserRole.Admin]: "Admin",
    [UserRole.TechnicalAdmin]: "Technical Admin",
    [UserRole.User]: "User",
  };

  return labels[role];
};

export const getFriendlyMeetingTypeLabel = (
  meetingType: MeetingType
): string => {
  const labels: { [key in MeetingType]: string } = {
    [MeetingType.WebConference]: "Web Conference",
    [MeetingType.InboundCall]: "Inbound Phone Call",
    [MeetingType.OutboundCall]: "Outbound Phone Call",
  };

  return labels[meetingType];
};

export const getFriendlyCRMFieldTypeLabel = (
  crmFieldType: CRMFieldType
): string => {
  const labels: { [key in CRMFieldType]: string } = {
    [CRMFieldType.Base64]: CRMFieldTypeLabel.Base64,
    [CRMFieldType.Boolean]: CRMFieldTypeLabel.Boolean,
    [CRMFieldType.Currency]: CRMFieldTypeLabel.Currency,
    [CRMFieldType.Date]: CRMFieldTypeLabel.Date,
    [CRMFieldType.DateTime]: CRMFieldTypeLabel.DateTime,
    [CRMFieldType.Double]: CRMFieldTypeLabel.Double,
    [CRMFieldType.Email]: CRMFieldTypeLabel.Email,
    [CRMFieldType.EncryptedString]: CRMFieldTypeLabel.EncryptedString,
    [CRMFieldType.HTML]: CRMFieldTypeLabel.HTML,
    [CRMFieldType.Id]: CRMFieldTypeLabel.Id,
    [CRMFieldType.Multipicklist]: CRMFieldTypeLabel.Multipicklist,
    [CRMFieldType.Percent]: CRMFieldTypeLabel.Percent,
    [CRMFieldType.Phone]: CRMFieldTypeLabel.Phone,
    [CRMFieldType.Picklist]: CRMFieldTypeLabel.Picklist,
    [CRMFieldType.Reference]: CRMFieldTypeLabel.Reference,
    [CRMFieldType.String]: CRMFieldTypeLabel.String,
    [CRMFieldType.Textarea]: CRMFieldTypeLabel.Textarea,
    [CRMFieldType.Time]: CRMFieldTypeLabel.Time,
    [CRMFieldType.Url]: CRMFieldTypeLabel.Url,
  };

  return labels[crmFieldType];
};

export const getFriendlyGenerationModelTypeLabel = (
  modelType: GenerationModelType
): string => {
  const labels: { [key in GenerationModelType]: string } = {
    [GenerationModelType.Basic]: "Basic Model",
    [GenerationModelType.Premium]: "Premium Model",
    [GenerationModelType.Reasoning]: "Reasoning Model",
    [GenerationModelType.BasicWebSearch]: "Basic Web Search Model",
  };

  return labels[modelType];
};

export const getFriendlyGenerationModelDescription = (
  modelType: GenerationModelType
): string => {
  const labels: { [key in GenerationModelType]: string } = {
    [GenerationModelType.Basic]: "Ideal for most tasks, uses fewer credits.",
    [GenerationModelType.Premium]:
      "More powerful and accurate, best for complex and detailed outputs.",
    [GenerationModelType.Reasoning]:
      "Best for complex tasks and prompts, uses more credits.",
    [GenerationModelType.BasicWebSearch]:
      "Use the most up-to-date information from the web when generating content.",
  };

  return labels[modelType];
};

export const getFriendlyAppendTypeLabel = (
  appendType: FieldConfigurationAppendType
): string => {
  const labels: { [key in FieldConfigurationAppendType]: string } = {
    [FieldConfigurationAppendType.Top]: "Append to Top",
    [FieldConfigurationAppendType.Bottom]: "Append to Bottom",
    [FieldConfigurationAppendType.Overwrite]: "Overwrite",
    [FieldConfigurationAppendType.Smart]: "Combine Intelligently",
    [FieldConfigurationAppendType.Merge]: "Merge Append",
  };

  return labels[appendType];
};
