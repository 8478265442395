import React, { useEffect, useState } from "react";
import { Cascader, Tag } from "antd";
import { useUpstreamWorkflowStates } from "../../../../util/data_hooks";
import { useParams } from "react-router-dom";
import { CRMFieldType } from "../../../../util/enums";
import SkeletonInput from "antd/es/skeleton/Input";
import { useWorkflowVariableDefinition } from "../util/useWorkflowVariableDefinition";
import { VariableDefinition } from "../../../../util/types";
import { DynamicVariableTag, generateColor } from "./DynamicVariableTag";

interface DynamicVariableSelectProps {
  value?: string;
  containerId?: string;
  isMultiSelect?: boolean;
  staticOptions?: { label: string; value: string | boolean }[];
  fieldType?: CRMFieldType;
  crmObjectType?: string;
  autoFocus?: boolean;
  variableDefinitionFilter?: (
    variableDefinitions: VariableDefinition[]
  ) => VariableDefinition[];
  onChange: (value: string) => void;
}

interface CascaderOption {
  label: string;
  value: string;
  key?: string;
  description?: string;
  tags?: string[];
  children?: CascaderOption[];
}

export function DynamicVariableSelect({
  value,
  containerId,
  isMultiSelect = false,
  autoFocus = false,
  fieldType,
  variableDefinitionFilter,
  onChange,
}: DynamicVariableSelectProps) {
  const { workflowUuid, workflowStateUuid } = useParams();
  const [focused, setFocused] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const {
    workflowStates: upstreamWorkflowStates,
    isLoading: upstreamWorkflowStatesLoading,
  } = useUpstreamWorkflowStates(workflowUuid, workflowStateUuid, {
    includeCurrent: window.location.pathname.includes("/workflow-transitions"),
    type: fieldType,
    includeVariableDefinitions: true,
  });

  const { variableDefinition, isLoading: variableDefinitionLoading } =
    useWorkflowVariableDefinition(value);

  useEffect(() => {
    if (autoFocus) setFocused(true);
  }, [autoFocus]);

  if (upstreamWorkflowStatesLoading || variableDefinitionLoading)
    return <SkeletonInput active style={{ width: 500 }} />;

  return (
    <Cascader<CascaderOption>
      id={containerId}
      style={{ width: "100%" }}
      ref={(input) => input && focused && input.focus()}
      getPopupContainer={(trigger) => trigger.parentNode}
      defaultOpen={autoFocus}
      onBlur={() => setFocused(false)}
      allowClear
      loading={upstreamWorkflowStatesLoading}
      placeholder={isMultiSelect ? "Select values" : "Select a value"}
      showSearch
      searchValue={searchValue}
      onSearch={setSearchValue}
      value={
        variableDefinition
          ? [variableDefinition.workflow_state_uuid, variableDefinition.key]
          : undefined
      }
      onChange={(value) => {
        if (!value) return onChange(null);

        const workflowStateUuid = value[0];
        const key = value.slice(1).join(".");

        onChange(`{{action.${workflowStateUuid}.${key}}}`);
      }}
      displayRender={() => {
        return <DynamicVariableTag variable={value} />;
      }}
      optionRender={(option: CascaderOption) => {
        if (option.value === "__EMPTY__") return <p>No results found.</p>;
        if ("children" in option) return <div>{option.label}</div>;

        return (
          <div>
            <p>{option.description}</p>
            <p style={{ color: "#8c8c8c" }}>{`{{${option.key}}}`}</p>

            {option?.tags?.map((tag, i) => (
              <Tag key={i} color={generateColor(tag)}>
                {tag}
              </Tag>
            ))}
          </div>
        );
      }}
      options={upstreamWorkflowStates.map((state, index) => {
        const filteredVariableDefinitions = variableDefinitionFilter
          ? variableDefinitionFilter(state.variable_definitions)
          : state.variable_definitions;

        return {
          label: `${index + 1}. ${state.name} Variables`,
          value: state.uuid,
          disabled: !filteredVariableDefinitions.length,
          children: filteredVariableDefinitions.map((definition) => {
            return {
              label: definition.description,
              value: definition.key,
              key: definition.key,
              description: definition.description,
              tags: definition.tags,
            };
          }),
        };
      })}
    />
  );
}
