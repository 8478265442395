import { Card, Col, Empty, Row, Skeleton, Statistic } from "antd";
import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useCreditTransactions } from "../../../util/data_hooks";

const getColor = (index: number) => {
  const colors = [
    "rgba(54, 162, 235)", // Blue
    "rgba(255, 99, 132)", // Red
    "rgba(75, 192, 192)", // Green
    "rgba(255, 206, 86)", // Yellow
    "rgba(153, 102, 255)", // Purple
    "rgba(255, 159, 64)", // Orange
  ];
  return colors[index % colors.length];
};

export function Usage() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const { data, isLoading: creditTransactionsLoading } =
    useCreditTransactions();

  const usageTypes = Array.from(
    new Set(data.summaries.flatMap((day) => Object.keys(day.types)))
  );

  if (creditTransactionsLoading) return <Skeleton active />;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card title="Usage Summary">
          <Row gutter={16}>
            <Col span={12}>
              <Statistic
                title="Today"
                value={data.today_total}
                suffix="Credits"
              />
            </Col>

            <Col span={12}>
              <Statistic
                title="This Month"
                value={data.month_to_date_total}
                suffix="Credits"
              />
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card title="Usage Breakdown (This Month)">
          {data.summaries.length === 0 ? (
            <Empty description="No usage data found." />
          ) : (
            <div style={{ height: 300 }}>
              <Bar
                options={{
                  maintainAspectRatio: false,
                  animation: false,
                  plugins: { legend: { display: false } },
                  scales: {
                    x: { stacked: true },
                    y: { stacked: true },
                  },
                }}
                data={{
                  labels: data.summaries.map(
                    (summary) =>
                      `${
                        summary.date
                      } - ${summary.total.toLocaleString()} Credits`
                  ),
                  datasets: usageTypes.map((type, index) => ({
                    label: type,
                    data: data.summaries.map((day) => day.types[type] || 0),
                    backgroundColor: getColor(index),
                  })),
                }}
              />
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
}
