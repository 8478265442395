import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Empty,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CRMField, CRMProvider } from "../../../../../util/types";
import { CRMFieldType, WorkflowActionType } from "../../../../../util/enums";
import { WorkflowTextInput } from "../../dynamic_variables/WorkflowTextInput";
import {
  useCRMObjectFields,
  useWorkflowActionCrmFields,
} from "../../../../../util/data_hooks";
import {
  createWorkflowActionCrmField,
  deleteWorkflowActionCrmField,
  replaceUndefinedValuesWithNull,
  updateWorkflowActionCrmField,
} from "../../../../../util/api";
import { useParams } from "react-router-dom";
import { useWorkflowStateContext } from "./WorkflowStateContext";
import { debounce } from "lodash";
import { DEBOUNCE_DELAY } from "../../shared/WorkflowStateEditorDrawer";
import { WorkflowNumberInput } from "../../dynamic_variables/WorkflowNumberInput";
import { WorkflowSelectInput } from "../../dynamic_variables/WorkflowSelectInput";
interface CRMFieldValuesFormItemProps {
  title: string;
  subTitle: string;
  crm: CRMProvider;
  crmObjectType: string;
  showRequiredFields?: boolean;
}

export function WorkflowActionCrmFields({
  title,
  subTitle,
  crm,
  crmObjectType,
  showRequiredFields,
}: CRMFieldValuesFormItemProps) {
  const { workflowUuid } = useParams();

  const { workflowState, isSaving, afterSave, setIsSaving } =
    useWorkflowStateContext();

  const [fieldSelectModalOpen, setFieldSelectModalOpen] =
    useState<boolean>(false);

  const [selectedFieldNames, setSelectedFieldNames] = useState<string[]>([]);

  const { objectFields, isLoading: isCrmObjectFieldsLoading } =
    useCRMObjectFields(crm, crmObjectType, true);

  const {
    workflowActionCrmFields,
    isLoading: isWorkflowActionCrmFieldsLoading,
    mutate: mutateWorkflowActionCrmFields,
  } = useWorkflowActionCrmFields(workflowUuid, workflowState.uuid);

  useEffect(() => {
    mutateWorkflowActionCrmFields();
  }, [crmObjectType]);

  const handleModalClose = () => {
    setFieldSelectModalOpen(false);
  };

  const isLoading =
    isWorkflowActionCrmFieldsLoading || isCrmObjectFieldsLoading;

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <Modal
        destroyOnClose
        open={fieldSelectModalOpen}
        onCancel={handleModalClose}
        title="Add Fields"
        footer={null}
      >
        <Row gutter={[12, 0]}>
          <Col span={20}>
            <Select
              ref={(input) => input && input.focus()}
              defaultOpen
              mode="multiple"
              optionFilterProp="label"
              showSearch
              style={{ width: "100%" }}
              placeholder="Select a CRM field..."
              onSelect={(value) => {
                setSelectedFieldNames((prev) => [...prev, value]);
              }}
              options={objectFields.map(
                ({ crm_field_label, crm_field_name }) => {
                  return {
                    label: `${crm_field_label} (${crm_field_name})`,
                    value: crm_field_name,
                  };
                }
              )}
            />
          </Col>

          <Col span={4}>
            <Button
              loading={isSaving}
              style={{ width: "100%" }}
              type="primary"
              onClick={async () => {
                if (selectedFieldNames.length === 0) {
                  setFieldSelectModalOpen(false);
                  return;
                }

                try {
                  setIsSaving(true);

                  await Promise.all(
                    selectedFieldNames.map(async (fieldName) => {
                      await createWorkflowActionCrmField(
                        workflowUuid,
                        workflowState.uuid,
                        { crm_field_name: fieldName }
                      );
                    })
                  );

                  await mutateWorkflowActionCrmFields();
                  afterSave();

                  setSelectedFieldNames([]);
                  setFieldSelectModalOpen(false);
                } catch (e) {
                  message.error(e.message);
                }

                setIsSaving(false);
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
      </Modal>

      <Row style={{ marginBottom: 15 }} justify="space-between" align="middle">
        <Col>
          <Typography.Title
            level={4}
            style={{ marginTop: 0, marginBottom: 10 }}
          >
            {title}
          </Typography.Title>

          <Typography.Text type="secondary">{subTitle}</Typography.Text>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={() => setFieldSelectModalOpen(true)}
            icon={<PlusCircleOutlined />}
          >
            Add Field Values
          </Button>
        </Col>
      </Row>

      {workflowActionCrmFields.length === 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No field values found"
        >
          <Button onClick={() => setFieldSelectModalOpen(true)}>
            Add Field Values
          </Button>
        </Empty>
      ) : (
        <>
          {workflowActionCrmFields.map((workflowActionCrmField) => {
            const crmField = objectFields.find(
              (field) =>
                field.crm_field_name === workflowActionCrmField.crm_field_name
            );

            if (!crmField) return null;

            return (
              <CRMFieldValueCard
                key={workflowActionCrmField.uuid}
                workflowActionCrmField={workflowActionCrmField}
                crmField={crmField}
                crmObjectType={crmObjectType}
                showRequiredFields={showRequiredFields}
              />
            );
          })}
        </>
      )}
    </>
  );
}

export interface WorkflowActionCrmField {
  uuid: string;
  crm_field_name: string;
  crm_field_value: any;
  overwrite_existing_value: boolean;
}

function CRMFieldValueCard({
  crmField,
  workflowActionCrmField,
  showRequiredFields,
  crmObjectType,
}: {
  crmField: CRMField;
  workflowActionCrmField: WorkflowActionCrmField;
  showRequiredFields: boolean;
  crmObjectType: string;
}) {
  const { workflowUuid } = useParams();
  const { workflowState, setIsSaving, afterSave } = useWorkflowStateContext();

  const { mutate: mutateWorkflowActionCrmFields } = useWorkflowActionCrmFields(
    workflowUuid,
    workflowState.uuid
  );

  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [value, setValue] = useState<any>(
    workflowActionCrmField.crm_field_value
  );

  const {
    crm_field_name,
    crm_field_label,
    crm_field_type,
    crm_field_required,
    crm_field_picklist_values,
  } = crmField;

  const debouncedHandleSubmit = useCallback(
    debounce(async (values: Partial<WorkflowActionCrmField>) => {
      try {
        setIsSaving(true);

        await updateWorkflowActionCrmField(
          workflowUuid,
          workflowState.uuid,
          workflowActionCrmField.uuid,
          replaceUndefinedValuesWithNull(values)
        );

        await mutateWorkflowActionCrmFields();
        await afterSave();
      } catch (e) {
        message.error(e.message);
      }

      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [
      workflowUuid,
      workflowState.uuid,
      workflowActionCrmField.uuid,
      mutateWorkflowActionCrmFields,
      afterSave,
    ]
  );

  const isRequired = showRequiredFields && crm_field_required;

  const showOverwriteOptions =
    workflowState.actionable_type === WorkflowActionType.UpdateCRMRecord;

  const getInput = () => {
    switch (crm_field_type) {
      case CRMFieldType.Id:
      case CRMFieldType.Reference:
      case CRMFieldType.Boolean:
      case CRMFieldType.Picklist:
      case CRMFieldType.Multipicklist:
      case CRMFieldType.Date:
      case CRMFieldType.Time:
      case CRMFieldType.DateTime: {
        let staticOptions = [];

        switch (crm_field_type) {
          case CRMFieldType.Picklist:
          case CRMFieldType.Multipicklist:
          case CRMFieldType.Reference:
            staticOptions = crm_field_picklist_values.map(
              ({ label, value }) => {
                return { label, value };
              }
            );
            break;
          case CRMFieldType.Boolean:
            staticOptions = [
              { label: "True", value: true },
              { label: "False", value: false },
            ];
        }

        return (
          <WorkflowSelectInput
            placeholder="Select Value"
            multiple={crm_field_type === CRMFieldType.Multipicklist}
            fieldType={crm_field_type}
            options={staticOptions}
            value={value}
            variableDefinitionFilter={(variableDefinitions) => {
              return variableDefinitions.filter((variableDefinition) => {
                if (variableDefinition.meta.crm_object_type) {
                  return (
                    variableDefinition.meta.crm_object_type === crmObjectType
                  );
                }

                return true;
              });
            }}
            onChange={(newValue) => {
              debouncedHandleSubmit({ crm_field_value: newValue });
              setValue(newValue);
            }}
          />
        );
      }
      case CRMFieldType.Double:
      case CRMFieldType.Percent:
      case CRMFieldType.Currency:
        return (
          <WorkflowNumberInput
            value={value}
            fieldType={crm_field_type}
            onChange={(newValue) => {
              debouncedHandleSubmit({ crm_field_value: newValue });
              setValue(newValue);
            }}
            variableDefinitionFilter={(variableDefinitions) => {
              return variableDefinitions.filter((variableDefinition) => {
                if (variableDefinition.meta.crm_object_type) {
                  return (
                    variableDefinition.meta.crm_object_type === crmObjectType
                  );
                }

                return true;
              });
            }}
          />
        );
      default:
        return (
          <WorkflowTextInput
            showBorder={false}
            value={value}
            onChange={(value) => {
              debouncedHandleSubmit({ crm_field_value: value });
              setValue(value);
            }}
          />
        );
    }
  };

  return (
    <Card
      style={{ marginBottom: 20 }}
      size="small"
      title={
        <Row justify="space-between">
          <Col>
            <Row gutter={[12, 0]} align="middle">
              <Col>
                <Typography.Text>
                  {crm_field_label}
                  {crm_field_label !== crm_field_name
                    ? ` (${crm_field_name})`
                    : ""}
                </Typography.Text>
              </Col>

              {showOverwriteOptions && (
                <Col>
                  <Select
                    defaultValue={
                      workflowActionCrmField.overwrite_existing_value
                    }
                    popupMatchSelectWidth={false}
                    size="small"
                    options={[
                      { label: "Overwrite", value: true },
                      { label: "Write if empty", value: false },
                    ]}
                    onSelect={(value) =>
                      debouncedHandleSubmit({ overwrite_existing_value: value })
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>

          {!isRequired && (
            <Col>
              <Button
                loading={isRemoving}
                danger
                type="text"
                size="small"
                key="remove-field"
                icon={<CloseCircleOutlined />}
                onClick={async () => {
                  try {
                    setIsRemoving(true);

                    await deleteWorkflowActionCrmField(
                      workflowUuid,
                      workflowState.uuid,
                      workflowActionCrmField.uuid
                    );

                    await afterSave();
                    await mutateWorkflowActionCrmFields();

                    message.success(`"${crm_field_label}" Removed`);
                  } catch (e) {
                    message.error(e.message);
                  }

                  setIsRemoving(false);
                }}
              >
                Remove
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      {getInput()}
    </Card>
  );
}
