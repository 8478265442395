import {
  Button,
  Col,
  Divider,
  Empty,
  Form,
  List,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useCallback, useState } from "react";
import {
  useFieldConfigurationGroups,
  useGenerateContentAction,
  useWorkflowActionFieldConfigurations,
} from "../../../../util/data_hooks";
import { WorkflowTextInput } from "../dynamic_variables/WorkflowTextInput";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import {
  createWorkflowActionFieldConfiguration,
  updateGenerateContentAction,
} from "../../../../util/api";
import {
  getFriendlyAppendTypeLabel,
  getFriendlyCRMFieldTypeLabel,
  getFriendlyGenerationModelTypeLabel,
} from "../../../../helpers/label_maps";
import { debounce, groupBy } from "lodash";
import { useWorkflowStateContext } from "./shared/WorkflowStateContext";
import { DEBOUNCE_DELAY } from "../shared/WorkflowStateEditorDrawer";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSearchParamsState } from "../../../hooks/useSearchParamsState";
import {
  MODEL_TYPE_ICON_MAP,
  WorkflowActionFieldConfigurationDrawer,
} from "./generate_content/WorkflowActionFieldConfigurationDrawer";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { gold } from "@ant-design/colors";
import { CreditsTag } from "../../../shared/CreditsTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faLayerGroup } from "@fortawesome/free-solid-svg-icons";

const WORKFLOW_ACTION_FIELD_CONFIGURATION_UUID_PARAM =
  "workflow_action_field_configuration_uuid";

enum GenerateContentActionAttribute {
  SystemPrompt = "system_prompt",
}

export interface GenerateContentAction {
  [GenerateContentActionAttribute.SystemPrompt]: string;
}

export function GenerateContent() {
  const [form] = Form.useForm<GenerateContentAction>();
  const { workflowUuid } = useParams();

  const { workflowState, isSaving, setIsSaving, afterSave } =
    useWorkflowStateContext();

  const {
    action,
    mutate: mutateAction,
    isLoading: isActionLoading,
  } = useGenerateContentAction(workflowUuid, workflowState.actionable_id);

  const {
    fieldConfigurationGroups,
    isLoading: isFieldConfigurationGroupsLoading,
  } = useFieldConfigurationGroups();

  const {
    workflowActionFieldConfigurations,
    isLoading: isWorkflowActionFieldConfigurationsLoading,
    mutate: mutateWorkflowActionFieldConfigurations,
  } = useWorkflowActionFieldConfigurations(workflowUuid, workflowState.uuid);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [systemPromptVisible, setSystemPromptVisible] =
    useState<boolean>(false);

  const [selectedFieldDefinitionUuids, setSelectedFieldDefinitionUuids] =
    useState<string[]>([]);

  const { credits } = useFlags();

  const [
    workflowActionFieldConfigurationUuid,
    setWorkflowActionFieldConfigurationUuid,
  ] = useSearchParamsState(
    WORKFLOW_ACTION_FIELD_CONFIGURATION_UUID_PARAM,
    null
  );

  const isLoading =
    isFieldConfigurationGroupsLoading ||
    isActionLoading ||
    isWorkflowActionFieldConfigurationsLoading;

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);
        await updateGenerateContentAction(
          workflowUuid,
          workflowState.actionable_id,
          form.getFieldsValue()
        );

        await mutateAction();
        await afterSave();
      } catch (e) {
        message.error(e.message);
      }
      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, mutateAction, afterSave]
  );

  const groupedWorkflowActionFieldConfigurations = groupBy(
    workflowActionFieldConfigurations,
    "field_definition.field_configuration_group.label"
  );

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <Modal
        title="Add Field Automations"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        destroyOnClose
        okButtonProps={{ loading: isSaving }}
        footer={null}
      >
        <Row gutter={[12, 0]}>
          <Col span={20}>
            <Select
              ref={(input) => input && input.focus()}
              defaultOpen
              style={{ width: "100%" }}
              placeholder="Select a field definition"
              showSearch
              optionFilterProp="label"
              options={fieldConfigurationGroups.map(
                ({ label, field_definitions }) => {
                  return {
                    label,
                    options: field_definitions.map(({ uuid, label }) => {
                      return { value: uuid, label };
                    }),
                  };
                }
              )}
              mode="multiple"
              onSelect={(value) => {
                setSelectedFieldDefinitionUuids((prev) => [...prev, value]);
              }}
            />
          </Col>

          <Col span={4}>
            <Button
              loading={isSaving}
              type="primary"
              onClick={async () => {
                if (selectedFieldDefinitionUuids.length === 0) {
                  setModalOpen(false);
                  return;
                }

                setIsSaving(true);

                let newFieldConfigurationUuid: string | null = null;

                try {
                  await Promise.all(
                    selectedFieldDefinitionUuids.map(
                      async (fieldDefinitionUuid) => {
                        const { uuid } =
                          await createWorkflowActionFieldConfiguration(
                            workflowUuid,
                            workflowState.uuid,
                            { field_definition_uuid: fieldDefinitionUuid }
                          );

                        newFieldConfigurationUuid = uuid;
                      }
                    )
                  );

                  await mutateWorkflowActionFieldConfigurations();
                  afterSave();

                  if (selectedFieldDefinitionUuids.length === 1) {
                    setWorkflowActionFieldConfigurationUuid(
                      newFieldConfigurationUuid
                    );
                  }

                  setSelectedFieldDefinitionUuids([]);
                  setModalOpen(false);
                } catch (e) {
                  message.error(e.message);
                }

                setIsSaving(false);
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
      </Modal>

      <WorkflowActionFieldConfigurationDrawer
        workflowActionFieldConfigurationUuid={
          workflowActionFieldConfigurationUuid
        }
        onClose={() => setWorkflowActionFieldConfigurationUuid(null)}
      />

      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Context
        </Typography.Title>

        <Space>
          <Typography.Text type="secondary">
            Context is used when generating the field automations below.
          </Typography.Text>

          <Typography.Link
            onClick={() => setSystemPromptVisible(!systemPromptVisible)}
          >
            {systemPromptVisible ? "Hide Context" : "Show Context"}
          </Typography.Link>
        </Space>
      </div>

      <Form
        form={form}
        initialValues={action}
        onValuesChange={debouncedHandleSubmit}
      >
        <Form.Item
          hidden={!systemPromptVisible}
          name={GenerateContentActionAttribute.SystemPrompt}
        >
          <WorkflowTextInput
            value={form.getFieldValue(
              GenerateContentActionAttribute.SystemPrompt
            )}
            onChange={(value) => {
              form.setFieldValue(
                GenerateContentActionAttribute.SystemPrompt,
                value
              );
            }}
          />
        </Form.Item>
      </Form>

      <Divider />

      <Row style={{ marginBottom: 15 }} justify="space-between" align="middle">
        <Col>
          <Typography.Title
            level={4}
            style={{ marginTop: 0, marginBottom: 10 }}
          >
            Field Automations
          </Typography.Title>

          <Typography.Text type="secondary">
            Generate content for your imported field definitions.
          </Typography.Text>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={() => setModalOpen(true)}
            icon={<PlusCircleOutlined />}
          >
            Add Field Automations
          </Button>
        </Col>
      </Row>

      {workflowActionFieldConfigurations.length === 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Add a field automation to generate content for any of your field definitions"
        >
          <Button onClick={() => setModalOpen(true)}>
            Add Field Automations
          </Button>
        </Empty>
      ) : (
        <Row gutter={[0, 12]}>
          {Object.entries(groupedWorkflowActionFieldConfigurations).map(
            ([fieldConfigurationGroupLabel, fieldConfigurations], index) => (
              <Col key={index} span={24}>
                <List
                  dataSource={fieldConfigurations}
                  bordered
                  header={
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      {fieldConfigurationGroupLabel} Automations
                    </Typography.Title>
                  }
                  renderItem={({
                    uuid,
                    field_definition,
                    credit_cost,
                    missing_required_fields,
                    screening_enabled,
                    append_type,
                    model_type,
                  }) => (
                    <HoverableListItem
                      isSelected={workflowActionFieldConfigurationUuid === uuid}
                      onClick={() =>
                        setWorkflowActionFieldConfigurationUuid(uuid)
                      }
                    >
                      <Row justify="space-between" style={{ width: "100%" }}>
                        <Col>
                          <Space>
                            <Typography.Text>
                              <Typography.Text strong>
                                {field_definition?.label}
                              </Typography.Text>{" "}
                              (
                              {getFriendlyCRMFieldTypeLabel(
                                field_definition?.field_type
                              )}
                              )
                            </Typography.Text>

                            {missing_required_fields && (
                              <Tooltip title="Missing required fields">
                                <ExclamationCircleTwoTone
                                  twoToneColor={gold[5]}
                                />
                              </Tooltip>
                            )}
                          </Space>
                        </Col>

                        <Col>
                          <Row>
                            {append_type && (
                              <Tooltip
                                title={getFriendlyAppendTypeLabel(append_type)}
                              >
                                <Tag color="purple">
                                  <FontAwesomeIcon
                                    icon={faLayerGroup}
                                    size="sm"
                                  />
                                </Tag>
                              </Tooltip>
                            )}

                            {screening_enabled && (
                              <Tooltip title="Screening Enabled">
                                <Tag color="green">
                                  <FontAwesomeIcon icon={faFilter} size="sm" />
                                </Tag>
                              </Tooltip>
                            )}

                            <Tooltip
                              title={getFriendlyGenerationModelTypeLabel(
                                model_type
                              )}
                            >
                              <Tag color="blue">
                                <FontAwesomeIcon
                                  icon={MODEL_TYPE_ICON_MAP[model_type]}
                                  size="sm"
                                />
                              </Tag>
                            </Tooltip>

                            {credits && <CreditsTag credits={credit_cost} />}
                          </Row>
                        </Col>
                      </Row>
                    </HoverableListItem>
                  )}
                />

                <Divider />
              </Col>
            )
          )}
        </Row>
      )}
    </>
  );
}

function HoverableListItem({
  children,
  isSelected,
  onClick,
}: {
  children: React.ReactNode;
  isSelected: boolean;
  onClick: () => void;
}) {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const hoverStyle = {
    backgroundColor: "rgba(0, 0, 0, 0.03)",
  };

  return (
    <List.Item
      style={{
        cursor: "pointer",
        ...((isHovered || isSelected) && hoverStyle),
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {children}
    </List.Item>
  );
}
