import React, { useEffect, useState } from "react";
import { useWorkflows } from "../../../util/data_hooks";
import {
  Table,
  Typography,
  Skeleton,
  Row,
  Button,
  Tag,
  Flex,
  Select,
} from "antd";
import { Workflow } from "../../../util/types";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { getFriendlyWorkflowTriggerLabel } from "../../../helpers/label_maps";
import { WorkflowDetailsEditorModal } from "./shared/WorkflowDetailsEditorModal";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { green } from "@ant-design/colors";
import { usePageTitle } from "../../hooks/usePageTitle";
import { PlusCircleOutlined } from "@ant-design/icons";
import { WorkflowTriggerType } from "../../../util/enums";
import { useWorkflowFilters } from "./util/useWorkflowFilters";
import { teamSelectTagRender } from "../../shared/teamSelectTagRender";

export function Workflows() {
  usePageTitle("Workflows");

  const navigate = useNavigate();

  const {
    isLoading: isTeamsLoading,
    teamMap,
    teamOptions,
    assignedTeamColors,
    teamFilterUuids,
    dispatchTeamFilterUuids,
  } = useWorkflowFilters();
  const { workflows, isLoading: isWorkflowsLoading } = useWorkflows();

  const [filteredWorkflows, setFilteredWorkflows] = useState<Workflow[]>([]);
  const [workflowDetailsEditorModalOpen, setWorkflowDetailsEditorModalOpen] =
    useState<boolean>(false);

  const isLoading = isWorkflowsLoading || isTeamsLoading;

  useEffect(() => {
    if (workflows) {
      setFilteredWorkflows(
        teamFilterUuids.length > 0
          ? workflows.filter((workflow) =>
              workflow.team_uuids.some((teamUuid) =>
                teamFilterUuids.includes(teamUuid)
              )
            )
          : workflows
      );
    }
  }, [workflows, teamFilterUuids]);

  const columns: ColumnsType<Workflow> = [
    {
      title: "Enabled",
      dataIndex: "enabled",
      width: "3%",
      align: "center",
      render: (enabled) =>
        enabled ? <CheckCircleTwoTone twoToneColor={green.primary} /> : "-",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Trigger",
      dataIndex: "triggerable_type",
      render: (workflowTrigger: WorkflowTriggerType) =>
        getFriendlyWorkflowTriggerLabel(workflowTrigger),
    },
    {
      title: "Team Usage",
      dataIndex: "team_uuids",
      width: "35%",
      render: (teamUuids: string[]) => {
        return (
          <Flex wrap>
            {teamUuids.map((teamUuid) => {
              const { team, color } = teamMap[teamUuid];

              return (
                <Tag
                  key={team.name}
                  color={color}
                  style={{ marginBottom: "0.5rem" }}
                >
                  {team.name}
                </Tag>
              );
            })}
          </Flex>
        );
      },
    },
  ];

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <WorkflowDetailsEditorModal
        teamOptions={teamOptions}
        assignedTeamColors={assignedTeamColors}
        open={workflowDetailsEditorModalOpen}
        setOpen={setWorkflowDetailsEditorModalOpen}
      />

      <Row justify="space-between" style={{ marginBottom: "0.5rem" }}>
        <Typography.Title level={3} style={{ marginTop: 0 }}>
          Workflows
        </Typography.Title>

        <Flex
          style={{ marginBottom: "0.5rem", width: "60%" }}
          justify="end"
          align="middle"
        >
          <Select
            tagRender={teamSelectTagRender(assignedTeamColors)}
            style={{ width: "60%", marginRight: "0.5rem" }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
            size="small"
            placeholder="Filter by team usage"
            allowClear
            options={teamOptions}
            onChange={(teamUuids: string[]) => {
              dispatchTeamFilterUuids(teamUuids);
            }}
            value={teamFilterUuids}
          />
          <Button
            icon={<PlusCircleOutlined />}
            type="primary"
            onClick={() => setWorkflowDetailsEditorModalOpen(true)}
          >
            Create New Workflow
          </Button>
        </Flex>
      </Row>

      <Table<Workflow>
        rowKey={({ uuid }) => uuid}
        onRow={({ uuid, enabled }) => {
          return {
            style: {
              cursor: "pointer",
              ...(!enabled && {
                background: "#f0f0f0",
                "&:hover": {
                  background: "#f0f0f0",
                },
              }),
            },
            onClick: () => navigate(`${uuid}`),
          };
        }}
        loading={isLoading}
        dataSource={filteredWorkflows?.sort(
          (a, b) => Number(b.enabled) - Number(a.enabled)
        )}
        columns={columns}
        pagination={false}
      />
    </>
  );
}
