import useSWR, { KeyedMutator } from "swr";
import {
  Meeting,
  Product,
  Pagination,
  Organization,
  User,
  FieldConfiguration,
  Workflow,
  Team,
  CrmRole,
  CRMRecord,
  CRMProvider,
  CRMObject,
  CRMField,
  AssociatedCRMRecord,
  FieldConfigurationGroup,
  SlackChannel,
  SlackUser,
  WorkflowInstance,
  WorkflowState,
  IntegrationInfo,
  FieldConfigurationGroupLayout,
  WorkflowTransition,
  FieldDefinitionWithUsage,
  OrganizationAnalytics,
  LayoutedFieldConfigurationGroup,
  AutoGeneratedField,
  FieldTestOutcomeConfigurationWithMeeting,
  FieldDefinition,
} from "./types";
import { get, forEach } from "lodash";
import { CRMFieldType, Integration, WorkflowStatus } from "./enums";
import { TEAM_MEETINGS_TABLE_FILTERS } from "../src/team/TeamMeetings";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  DEFAULT_FILTERS,
  MeetingTableFilters,
} from "../src/shared/MeetingsTable";
import { MY_MEETINGS_TABLE_FILTERS } from "../src/meetings/Meetings";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useContext, useEffect } from "react";
import { AppContext } from "../src/App";
import { UpdateCrmRecordAction } from "../src/settings/workflows/action_editors/UpdateCRMRecord";
import { CreateCrmRecordAction } from "../src/settings/workflows/action_editors/CreateCRMRecord";
import { CreateSlackChannelAction } from "../src/settings/workflows/action_editors/CreateSlackChannel";
import { SendTeamsMessageAction } from "../src/settings/workflows/action_editors/SendTeamsMessage";
import { SendSlackMessageAction } from "../src/settings/workflows/action_editors/SendSlackMessage";
import { GenerateContentAction } from "../src/settings/workflows/action_editors/GenerateContent";
import { WorkflowActionCrmField } from "../src/settings/workflows/action_editors/shared/WorkflowActionCrmFields";
import { WorkflowActionFieldConfiguration } from "../src/settings/workflows/action_editors/generate_content/WorkflowActionFieldConfigurationDrawer";

export interface swrError extends Error {
  status?: number;
  info?: string;
}

const fetcher = async (url) => {
  const res = await fetch(url);

  if (res.ok) return res.json();

  const error: swrError = new Error(
    "An error occurred while fetching the data."
  );

  error.info = await res.json();
  error.status = res.status;

  throw error;
};

export interface RequestStatus {
  error: boolean;
  isLoading: boolean;
  isValidating?: boolean;
  mutate: KeyedMutator<unknown>;
}

const meetingsQueryString = (
  filters: MeetingTableFilters,
  meetingName: string
) => {
  let queryString = "";

  const teamUuids = filters.team_uuids || [];
  const userUuids = filters.users || [];
  const crmRecordIds = filters.crm_record_ids || [];
  const dateRange = filters.date_range || [];
  const meetingTypes = filters.meeting_types || [];
  const meetingStatuses = filters.meeting_status || [];

  if (teamUuids.length > 0) queryString += `&team_uuids=${teamUuids}`;
  if (userUuids.length > 0) queryString += `&user_uuids=${userUuids}`;
  if (crmRecordIds.length > 0) queryString += `&crm_record_ids=${crmRecordIds}`;
  if (meetingTypes.length > 0) queryString += `&meeting_types=${meetingTypes}`;
  if (meetingStatuses.length > 0)
    queryString += `&meeting_statuses=${meetingStatuses}`;
  if (dateRange.length > 0) queryString += `&date_range=${dateRange}`;
  if (meetingName) queryString += `&meeting_name=${meetingName}`;

  return queryString;
};

export const useMeetings = ({
  page,
  pageSize,
  meetingName,
}: {
  page: number;
  pageSize: number;
  meetingName: string;
}): {
  meetings: Meeting[];
  meta: Pagination;
} & RequestStatus => {
  const [savedFilters] = useLocalStorage<MeetingTableFilters>(
    MY_MEETINGS_TABLE_FILTERS,
    DEFAULT_FILTERS
  );

  let endpoint = `/api/meetings?page=${page}&page_size=${pageSize}`;
  endpoint += meetingsQueryString(savedFilters, meetingName);

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    refreshInterval: (data) => (data?.meta?.is_syncing ? 3000 : 30000),
  });

  return {
    meetings: get(data, "meetings", []),
    meta: get(data, "meta", {}),
    error,
    isLoading,
    mutate,
  };
};

export const useOrganizationMeetings = ({
  page,
  pageSize,
  meetingName,
}: {
  page: number;
  pageSize: number;
  meetingName?: string;
}): {
  organizationMeetings: Meeting[];
  meta: Pagination;
  analytics: OrganizationAnalytics;
} & RequestStatus => {
  const [savedFilters] = useLocalStorage<MeetingTableFilters>(
    TEAM_MEETINGS_TABLE_FILTERS,
    DEFAULT_FILTERS
  );

  let endpoint = `/api/organization_meetings?page_size=${pageSize}&page=${page}`;
  endpoint += meetingsQueryString(savedFilters, meetingName);

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return {
    organizationMeetings: get(data, "organization_meetings", []),
    meta: get(data, "meta", {}),
    analytics: get(data, "analytics", {}),
    error,
    isLoading,
    mutate,
  };
};

export const useMeeting = (
  meetingUuid?: string
): {
  meeting: Meeting | undefined;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<Meeting>(
    meetingUuid ? `/api/meetings/${meetingUuid}` : null,
    fetcher,
    {
      refreshInterval: (meeting) => {
        if (!meeting?.predictions_completed_at) {
          return 5000;
        }

        if (meeting?.append_requested_at && !meeting?.append_completed_at) {
          return 2000;
        }

        return 0;
      },
    }
  );

  return { meeting: data, error, isLoading, mutate };
};

export const useMeetingLayoutedAutoGeneratedFields = (
  meetingUuid?: string
): {
  meetingLayoutedAutoGeneratedFields: {
    field_configuration_group: FieldConfigurationGroup;
    associated_crm_record: AssociatedCRMRecord;
    fields: {
      field_definition: FieldDefinition;
      auto_generated_field: AutoGeneratedField;
    }[];
  }[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<
    {
      field_configuration_group: FieldConfigurationGroup;
      associated_crm_record: AssociatedCRMRecord;
      fields: {
        field_definition: FieldDefinition;
        auto_generated_field: AutoGeneratedField;
      }[];
    }[]
  >(
    meetingUuid
      ? `/api/meetings/${meetingUuid}/layouted_auto_generated_fields`
      : null,
    fetcher
  );

  return {
    meetingLayoutedAutoGeneratedFields: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useProducts = (
  page: number,
  pageSize: number
): {
  products: Product[];
  meta: Pagination | undefined;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<{
    products: Product[];
    meta: Pagination;
  }>(`/api/products?page_size=${pageSize}&page=${page}`, fetcher);

  return {
    products: get(data, "products", []),
    meta: data ? get(data, "meta") : undefined,
    error,
    isLoading,
    mutate,
  };
};

export const useProduct = (
  uuid: string
): {
  product: Product;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    uuid ? `/api/products/${uuid}` : null,
    fetcher
  );

  return { product: data || {}, error, isLoading, mutate };
};

export const useOrganizations = (): {
  organizations: Organization[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<Organization[]>(
    `/api/organizations`,
    fetcher
  );

  return {
    organizations: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useOrganization = (
  organizationUuid: string
): {
  organization: Organization;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/organizations/${organizationUuid}`,
    fetcher
  );

  return { organization: data || {}, error, isLoading, mutate };
};

export const useUser = (): { user: User } & RequestStatus => {
  const ldClient = useLDClient();
  const environmentContext = useContext(AppContext);
  const { data, error, isLoading, mutate } = useSWR(`/api/users/me`, fetcher);

  useEffect(() => {
    if (data && environmentContext.environment !== "test") {
      const user = data as User;
      const context = {
        kind: "multi",
        organization: {
          key: user.organization.uuid,
          name: user.organization.name,
          environment: environmentContext.environment,
        },
        user: {
          key: user.uuid,
          name: user.email,
          email: user.email,
          roles: user.roles,
          team: user.team.name,
          environment: environmentContext.environment,
          is_impersonating: !!environmentContext.user_impersonation_email,
        },
      };
      // Update LaunchDarkly context with user information
      ldClient?.identify(context);
    }
  }, [data, ldClient]);

  return { user: data || {}, error, isLoading, mutate };
};

export const useMyIntegrations = (
  shouldFetch = true
): {
  meetingIntegrations: IntegrationInfo[];
  crmIntegration: IntegrationInfo;
  notificationIntegrations: IntegrationInfo[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? `/api/integrations` : null,
    fetcher
  );

  return {
    meetingIntegrations: get(data, "meeting_integrations", []),
    crmIntegration: get(data, "crm_integration", null),
    notificationIntegrations: get(data, "notification_integrations", []),
    error,
    isLoading,
    mutate,
  };
};

export const useOrganizationUsersIntegrations = (
  shouldFetch = true
): {
  userIntegrations: {
    uuid: string;
    meeting_integrations: IntegrationInfo[];
    crm_integration: IntegrationInfo;
    notification_integrations: IntegrationInfo[];
  }[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    shouldFetch ? `/api/integrations/organization_users` : null,
    fetcher
  );

  return { userIntegrations: data || [], error, isLoading, mutate };
};

export const useAssociatedCRMRecords = (
  meetingUuid?: string,
  isPredicted?: boolean
): {
  associatedCRMRecords: AssociatedCRMRecord[];
} & RequestStatus => {
  let endpoint = `/api/meetings/${meetingUuid}/associated_crm_records`;
  if (isPredicted) endpoint += `?is_predicted=${isPredicted}`;

  const { data, error, isLoading, mutate, isValidating } = useSWR<
    AssociatedCRMRecord[]
  >(meetingUuid ? endpoint : null, fetcher);

  return {
    associatedCRMRecords: data || [],
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useOrganizationAssociatedCRMRecords = (): {
  associatedCRMRecords: AssociatedCRMRecord[];
} & RequestStatus => {
  const { data, error, isLoading, mutate, isValidating } = useSWR<
    AssociatedCRMRecord[]
  >(`/api/organizations/associated_crm_records`, fetcher);

  return {
    associatedCRMRecords: data || [],
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useOrganizationFieldConfiguration = (
  fieldConfigurationUuid: string
): {
  fieldConfiguration: FieldConfiguration | undefined;
} & RequestStatus => {
  const { data, error, isLoading, mutate, isValidating } =
    useSWR<FieldConfiguration>(
      `/api/field_configurations/${fieldConfigurationUuid}`,
      fetcher
    );

  return {
    fieldConfiguration: data,
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useOrganizationPlaygroundMeetings = (
  page: number = 0,
  pageSize: number = 10,
  teamUuids: string[] = [],
  meetingName: string
): {
  meetings: Meeting[];
  meeting_summaries: { [meeting_uuid: string]: string };
  meta: Pagination;
} & RequestStatus => {
  const query = buildQueryParams({
    team_uuids: teamUuids,
    meeting_name: meetingName,
    page_size: pageSize,
    page,
  });
  const { data, error, isLoading, mutate, isValidating } = useSWR(
    `/api/organizations/playground_meetings${query}`,
    fetcher,
    {
      refreshInterval: (data) =>
        data?.pending_transcript_count > 0 ? 2000 : 0,
    }
  );

  return {
    meetings: get(data, "organization_meetings", []),
    meeting_summaries: get(data, "meeting_summaries", {}),
    meta: get(data, "meta", {}),
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useCRMObjects = (
  crm: CRMProvider
): {
  objects: CRMObject[];
} & RequestStatus => {
  const endpoint = crm
    ? `/api/crm/objects?crm_provider_name=${crm}`
    : "/api/crm/objects";

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);
  return { objects: data || [], error, isLoading, mutate };
};

export const useCRMObjectFields = (
  crm: CRMProvider,
  objectType: CRMObject | string,
  includeAllFields: boolean = false
): {
  objectFields: CRMField[];
} & RequestStatus => {
  const endpoint =
    crm && objectType
      ? `/api/crm/object_fields?crm_provider_name=${crm}&object_type=${objectType}&include_all_fields=${includeAllFields}`
      : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return { objectFields: data || [], error, isLoading, mutate };
};

export const useFindCRMRecordById = (
  object_type: string,
  record_name_property: string,
  id: string
): {
  record: CRMRecord;
} & RequestStatus => {
  let endpoint = `/api/crm/find_record?object_type=${object_type}`;

  if (record_name_property) {
    endpoint += `&record_name_property=${record_name_property}`;
  }

  if (id) endpoint += `&id=${id}`;

  const { data, error, mutate, isLoading } = useSWR(
    object_type ? endpoint : null,
    fetcher
  );
  return { record: data, error, mutate, isLoading };
};

export const buildQueryParams = (params) => {
  let query = ``;
  forEach(params, (value, key) => {
    if (!query.length) {
      query += `?${key}=${value}`;
    } else {
      query += `&${key}=${value}`;
    }
  });

  return query;
};

export const useWorkflows = (): { workflows: Workflow[] } & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    () => `/api/workflows`,
    fetcher
  );

  return { workflows: data, error, isLoading, mutate };
};

export const useWorkflow = (
  workflowUuid: string
): { workflow: Workflow } & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/workflows/${workflowUuid}`,
    fetcher
  );

  return { workflow: data, error, isLoading, mutate };
};

export const useWorkflowInstances = (
  workflowUuid: string,
  page: number = 0,
  pageSize: number = 10
): {
  workflowInstances: WorkflowInstance[];
  meta: Pagination;
} & RequestStatus => {
  console.log(workflowUuid, page, pageSize);
  const { data, error, isLoading, mutate } = useSWR(
    () =>
      workflowUuid
        ? `/api/workflows/${workflowUuid}/workflow_instances?page=${page}&page_size=${pageSize}`
        : null,
    fetcher,
    { refreshInterval: 2500 }
  );

  return {
    workflowInstances: data ? get(data, "workflow_instances", []) : [],
    meta: data ? get(data, "meta") : undefined,
    error,
    isLoading,
    mutate,
  };
};

export const useWorkflowInstance = (
  workflowUuid: string,
  workflowInstanceUuid: string
): { workflowInstance: WorkflowInstance } & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/workflows/${workflowUuid}/workflow_instances/${workflowInstanceUuid}`,
    fetcher,
    {
      refreshInterval: (data) =>
        data?.status === WorkflowStatus.InProgress ? 2000 : 0,
    }
  );

  return { workflowInstance: data, error, isLoading, mutate };
};

export const useWorkflowState = (
  workflowUuid: string,
  workflowStateUuid: string
): { workflowState: WorkflowState } & RequestStatus => {
  const endpoint =
    workflowUuid && workflowStateUuid
      ? `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}`
      : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return { workflowState: data, error, isLoading, mutate };
};

export const useWorkflowStates = (
  workflowUuid: string
): { workflowStates: WorkflowState[] } & RequestStatus => {
  const endpoint = workflowUuid
    ? `/api/workflows/${workflowUuid}/workflow_states`
    : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return { workflowStates: data || [], error, isLoading, mutate };
};

export const useWorkflowTransition = (
  workflowTransitionUuid: string
): { workflowTransition: WorkflowTransition } & RequestStatus => {
  const endpoint = workflowTransitionUuid
    ? `/api/workflow_transitions/${workflowTransitionUuid}`
    : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher);

  return { workflowTransition: data, error, isLoading, mutate };
};

export const useTeam = (
  teamUuid: string
): {
  team: Team | undefined;
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<Team>(
    () => (teamUuid ? `/api/teams/${teamUuid}` : null),
    fetcher
  );

  return {
    team: data,
    error,
    isLoading,
    mutate,
  };
};

export const useTeams = (): {
  teams: Team[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<Team[]>(
    () => `/api/teams`,
    fetcher
  );

  return {
    teams: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useOrganizationCrmRoles = (
  organization: Organization
): {
  crmRoles: CrmRole[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<CrmRole[]>(
    () => `/api/organizations/${organization.uuid}/crm_roles`,
    fetcher
  );

  return {
    crmRoles: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useFieldConfigurationGroups = (): {
  fieldConfigurationGroups: FieldConfigurationGroup[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<FieldConfigurationGroup[]>(
    () => "/api/field_configuration_groups",
    fetcher
  );

  return {
    fieldConfigurationGroups: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useFieldDefinition = (
  fieldDefinitionUuid: string
): {
  fieldDefinition: FieldDefinitionWithUsage;
} & RequestStatus => {
  const { data, error, isLoading, mutate, isValidating } =
    useSWR<FieldDefinitionWithUsage>(
      () =>
        fieldDefinitionUuid
          ? `/api/field_definitions/${fieldDefinitionUuid}`
          : null,
      fetcher
    );

  return {
    fieldDefinition: data,
    error,
    isValidating,
    isLoading,
    mutate,
  };
};

export const useSlackChannels = (): {
  channels: SlackChannel[];
} & RequestStatus => {
  const endpoint = "/api/slack/channels";

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });
  return { channels: data || [], error, isLoading, mutate };
};

export const useSlackUsers = (): {
  slackUsers: SlackUser[];
} & RequestStatus => {
  const endpoint = "/api/slack/users";

  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { slackUsers: data || [], error, isLoading, mutate };
};

export const useOrganizationUsers = (
  organization?: Organization
): {
  users: User[];
} & RequestStatus => {
  let query = "";
  if (organization) {
    query = buildQueryParams({ id: organization.uuid });
  }
  const { data, error, isLoading, mutate } = useSWR<User[]>(
    () => `/api/organizations/users` + query,
    fetcher
  );

  return {
    users: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useFieldConfigurationGroupLayouts = (team?: {
  uuid: string;
}): {
  fieldConfigurationGroupLayouts: FieldConfigurationGroupLayout[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<
    FieldConfigurationGroupLayout[]
  >(() => `/api/teams/${team.uuid}/field_configuration_group_layouts`, fetcher);

  return {
    fieldConfigurationGroupLayouts: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useIntegrationUsers = (
  integration: Integration
): {
  response: {
    merged_users: {
      user_uuid: string;
      user_email: string;
      integration_user_id: string;
      integration_user_name: string;
      integration_user_email: string;
    }[];
    integration_users: {
      id: string;
      name: string;
      email: string;
    }[];
  };
} & RequestStatus => {
  const endpoint = `/api/integrations/connected_users?integration_provider_name=${integration}`;

  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    response: data || { merged_users: [], integration_users: [] },
    error,
    isLoading,
    mutate,
  };
};

export const useMicrosoftTeams = (): {
  teams: { id: string; name: string }[];
} & RequestStatus => {
  const endpoint = "/api/microsoft/teams";

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { teams: data || [], error, isLoading, mutate };
};

export const useMicrosoftChannels = (
  teamId: string
): {
  channels: { id: string; name: string }[];
} & RequestStatus => {
  const endpoint = teamId ? `/api/microsoft/channels?team_id=${teamId}` : null;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { channels: data || [], error, isLoading, mutate };
};

export const mutateLayoutedAutoGeneratedField = (
  autoGeneratedFieldUuid: string,
  attributes: Partial<AutoGeneratedField>,
  mutate: (data?: KeyedMutator<LayoutedFieldConfigurationGroup[]>) => void
) =>
  mutate(async (layoutedFieldConfigurationGroups) => {
    return (
      layoutedFieldConfigurationGroups as LayoutedFieldConfigurationGroup[]
    ).map((group) => {
      return {
        ...group,
        layouted_fields: group.layouted_fields.map((field) => {
          if (field.auto_generated_field?.uuid === autoGeneratedFieldUuid) {
            return {
              ...field,
              pending_review: isNullOrUndefined(attributes.accepted)
                ? true
                : false,
              auto_generated_field: {
                ...field.auto_generated_field,
                ...attributes,
              },
            };
          } else {
            return field;
          }
        }),
      };
    });
  });

export const useLayoutedFieldConfigurationGroups = (
  meetingUuid: string,
  {
    requestPredictions,
    fieldStatus,
    teamUuid,
  }: {
    requestPredictions: boolean;
    fieldStatus: "visible" | "automated";
    teamUuid?: string;
  }
): {
  layoutedFieldConfigurationGroups: LayoutedFieldConfigurationGroup[];
} & {
  mutate: KeyedMutator<LayoutedFieldConfigurationGroup[]>;
} & RequestStatus => {
  let endpoint = `/api/meetings/${meetingUuid}/layouted_field_configuration_groups?request_predictions=${requestPredictions}&field_status=${fieldStatus}`;
  if (teamUuid) endpoint += `&team_uuid=${teamUuid}`;

  const { data, error, isLoading, mutate, isValidating } = useSWR<
    LayoutedFieldConfigurationGroup[]
  >(meetingUuid ? endpoint : null, fetcher);

  return {
    layoutedFieldConfigurationGroups: data || [],
    error,
    isLoading,
    isValidating,
    mutate,
  };
};

export const fieldTestOutcomeConfigurations = (
  fieldConfiguration: FieldConfiguration
): {
  fieldTestOutcomeConfigurations: FieldTestOutcomeConfigurationWithMeeting[];
} & RequestStatus => {
  const { data, error, isLoading, mutate } = useSWR<
    FieldTestOutcomeConfigurationWithMeeting[]
  >(
    () =>
      `/api/field_configurations/${fieldConfiguration.uuid}/field_test_outcome_configurations`,
    fetcher
  );

  return {
    fieldTestOutcomeConfigurations: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useSendSlackMessageAction = (
  workflowUuid: string,
  actionableUuid: string
): {
  action: SendSlackMessageAction;
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_actions/send_slack_message_actions/${actionableUuid}`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { action: data, error, isLoading, mutate };
};

export const useCreateSlackChannelAction = (
  workflowUuid: string,
  actionableUuid: string
): {
  action: CreateSlackChannelAction;
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_actions/create_slack_channel_actions/${actionableUuid}`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { action: data, error, isLoading, mutate };
};

export const useSendTeamsMessageAction = (
  workflowUuid: string,
  actionableUuid: string
): {
  action: SendTeamsMessageAction;
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_actions/send_teams_message_actions/${actionableUuid}`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { action: data, error, isLoading, mutate };
};

export const useUpdateCrmRecordAction = (
  workflowUuid: string,
  actionableUuid: string
): {
  action: UpdateCrmRecordAction;
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_actions/update_crm_record_actions/${actionableUuid}`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { action: data, error, isLoading, mutate };
};

export const useCreateCrmRecordAction = (
  workflowUuid: string,
  actionableUuid: string
): {
  action: CreateCrmRecordAction;
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_actions/create_crm_record_actions/${actionableUuid}`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { action: data, error, isLoading, mutate };
};

export const useGenerateContentAction = (
  workflowUuid: string,
  actionableUuid: string
): {
  action: GenerateContentAction;
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_actions/generate_content_actions/${actionableUuid}`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { action: data, error, isLoading, mutate };
};

export const useWorkflowActionCrmFields = (
  workflowUuid: string,
  workflowStateUuid: string
): {
  workflowActionCrmFields: WorkflowActionCrmField[];
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_crm_fields`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { workflowActionCrmFields: data || [], error, isLoading, mutate };
};

export const useWorkflowActionFieldConfigurations = (
  workflowUuid: string,
  workflowStateUuid: string
): {
  workflowActionFieldConfigurations: WorkflowActionFieldConfiguration[];
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_field_configurations`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    workflowActionFieldConfigurations: data || [],
    error,
    isLoading,
    mutate,
  };
};

export const useWorkflowActionFieldConfiguration = (
  workflowUuid: string,
  workflowStateUuid: string,
  workflowActionFieldConfigurationUuid: string
): {
  workflowActionFieldConfiguration: WorkflowActionFieldConfiguration;
} & RequestStatus => {
  const endpoint = `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_field_configurations/${workflowActionFieldConfigurationUuid}`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    workflowActionFieldConfiguration: data || {
      field_definition: { field_configuration_group: {} },
    },
    error,
    isLoading,
    mutate,
  };
};

export const useUpstreamWorkflowStates = (
  workflowUuid: string,
  workflowStateUuid: string,
  {
    includeCurrent,
    type,
    includeVariableDefinitions = false,
  }: {
    includeCurrent: boolean;
    type?: CRMFieldType;
    includeVariableDefinitions?: boolean;
  }
): {
  workflowStates: WorkflowState[];
} & RequestStatus => {
  let endpoint = `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/upstream_workflow_states?include_current=${includeCurrent}`;

  if (type) endpoint += `&type=${type}`;
  if (includeVariableDefinitions)
    endpoint += `&include_variable_definitions=true`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { workflowStates: data || [], error, isLoading, mutate };
};

export const useCreditTransactions = (): {
  data: {
    month_to_date_total: number;
    today_total: number;
    summaries: {
      date: string;
      total: number;
      types: { [key: string]: number };
    }[];
  };
} & RequestStatus => {
  const endpoint = `/api/credit_transactions`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { data: data || { summaries: [] }, error, isLoading, mutate };
};

export const useStripeCreditGrants = (): {
  creditGrants: {
    name: string;
    amount_granted: number;
    amount_used: number;
    effective_at: string;
    expires_at: string;
    voided_at: string;
  }[];
} & RequestStatus => {
  const endpoint = `/api/stripe/credit_grants`;

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { creditGrants: data || [], error, isLoading, mutate };
};

export const useStripeCustomers = (): {
  customers: { id: string; name: string; email: string }[];
} & RequestStatus => {
  const endpoint = "/api/stripe/customers";

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { customers: data || [], error, isLoading, mutate };
};

export const useStripeUpcomingInvoice = (): {
  upcomingInvoice: any;
} & RequestStatus => {
  const endpoint = "/api/stripe/upcoming_invoice";

  const { data, error, isLoading, mutate } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  return { upcomingInvoice: data, error, isLoading, mutate };
};
