import React, { useState } from "react";
import { Form, Modal, Radio, Select, Switch } from "antd";
import {
  parseVariable,
  WorkflowVariableFormatType,
  WorkflowVariableType,
} from "../util/helpers";
import { DynamicVariableSelect } from "./DynamicVariableSelect";
import { DynamicVariableDateTime } from "./DynamicVariableDateTime";
import { CRMFieldType } from "../../../../util/enums";
import { useWorkflowVariableDefinition } from "../util/useWorkflowVariableDefinition";
import { VariableDefinition } from "../../../../util/types";

interface DynamicVariableModalProps {
  open: boolean;
  allowFormat?: boolean;
  value?: string;
  fieldType?: CRMFieldType;
  variableDefinitionFilter?: (
    variableDefinitions: VariableDefinition[]
  ) => VariableDefinition[];
  setOpen: (open: boolean) => void;
  onInsert: (value: string) => void;
}

export function DynamicVariableModal({
  open,
  value = null,
  allowFormat = true,
  fieldType,
  variableDefinitionFilter,
  setOpen,
  onInsert,
}: DynamicVariableModalProps) {
  const { variableType, formatType } = parseVariable(value);

  const [selectedVariable, setSelectedVariable] = useState<string>(
    variableType ? value : null
  );
  const [selectedVariableType, setSelectedVariableType] =
    useState<WorkflowVariableType>(variableType || WorkflowVariableType.Action);

  const { variableDefinition } =
    useWorkflowVariableDefinition(selectedVariable);

  const onFormatClear = () => {
    const { variableType, attributes } = parseVariable(selectedVariable);
    setSelectedVariable(`{{${variableType}.${attributes.join(".")}}}`);
  };

  return (
    <Modal
      destroyOnClose
      title="Insert Variable"
      open={open}
      width="50rem"
      okText="Insert Variable"
      onCancel={() => setOpen(false)}
      onOk={() => {
        onInsert(selectedVariable);
        setOpen(false);
      }}
      okButtonProps={{
        disabled: !selectedVariable,
      }}
    >
      <Form layout="vertical">
        <Form.Item label="Variable Type">
          <Radio.Group
            value={selectedVariableType}
            onChange={(e) => {
              setSelectedVariable(null);
              setSelectedVariableType(e.target.value);
            }}
            optionType="button"
            options={[
              {
                label: "Response Attribute",
                value: WorkflowVariableType.Action,
              },
              {
                label: "Relative Date/Time",
                value: WorkflowVariableType.Datetime,
              },
            ]}
          />
        </Form.Item>

        {selectedVariableType === WorkflowVariableType.Action && (
          <Form.Item label="Variable Name">
            <DynamicVariableSelect
              autoFocus
              fieldType={fieldType}
              value={selectedVariable}
              variableDefinitionFilter={variableDefinitionFilter}
              onChange={setSelectedVariable}
            />
          </Form.Item>
        )}

        {selectedVariableType === WorkflowVariableType.Datetime && (
          <Form.Item label="Relative Date & Time">
            <DynamicVariableDateTime
              value={selectedVariable}
              onChange={setSelectedVariable}
            />
          </Form.Item>
        )}

        {[CRMFieldType.Date, CRMFieldType.DateTime, CRMFieldType.Time].includes(
          variableDefinition?.type
        ) &&
          allowFormat && (
            <Form.Item label="Format (Optional)">
              <Select
                allowClear
                defaultValue={formatType}
                placeholder="Select format type (optional)"
                options={[
                  {
                    label: "Format Date",
                    value: WorkflowVariableFormatType.Date,
                  },
                  {
                    label: "Format Date & Time",
                    value: WorkflowVariableFormatType.Datetime,
                  },
                ]}
                onChange={(value) => {
                  const { variableType, attributes } =
                    parseVariable(selectedVariable);

                  if (!value) return;

                  setSelectedVariable(
                    `{{${value} ${variableType}.${attributes.join(".")}}}`
                  );
                }}
                onClear={onFormatClear}
              />
            </Form.Item>
          )}

        {[
          CRMFieldType.Picklist,
          CRMFieldType.Multipicklist,
          CRMFieldType.Reference,
        ].includes(variableDefinition?.type) &&
          allowFormat && (
            <Form.Item label="Format to use picklist label (Optional)">
              <Switch
                defaultChecked={
                  formatType === WorkflowVariableFormatType.FormatPicklist
                }
                onChange={(checked) => {
                  const { variableType, attributes } =
                    parseVariable(selectedVariable);

                  const rootVariable = `${variableType}.${attributes.join(
                    "."
                  )}`;

                  setSelectedVariable(
                    checked
                      ? `{{format_picklist ${rootVariable}}}`
                      : `{{${rootVariable}}}`
                  );
                }}
              />
            </Form.Item>
          )}

        {[CRMFieldType.Currency, CRMFieldType.Double].includes(
          variableDefinition?.type
        ) &&
          allowFormat && (
            <Form.Item label="Format (Optional)">
              <Select
                allowClear
                defaultValue={formatType}
                placeholder="Select format type (optional)"
                options={[
                  {
                    label: "Format Number",
                    value: WorkflowVariableFormatType.FormatNumber,
                  },
                ]}
                onChange={(value) => {
                  const { variableType, attributes } =
                    parseVariable(selectedVariable);

                  if (!value) return;

                  setSelectedVariable(
                    `{{${value} ${variableType}.${attributes.join(".")}}}`
                  );
                }}
                onClear={onFormatClear}
              />
            </Form.Item>
          )}
      </Form>
    </Modal>
  );
}
