import React from "react";
import { ConfigProvider } from "antd";
import { createRoot } from "react-dom/client";
import { store } from "../src/redux/store";
import { Provider } from "react-redux";
import { LDProvider } from "launchdarkly-react-client-sdk";

import App from "../src/App";
import { LDFlags } from "../util/LD_flags";

document.addEventListener("DOMContentLoaded", function () {
  const appElement = document.getElementById("root");

  const environment = appElement?.getAttribute("data-environment");
  const user_roles = appElement?.getAttribute("data-user-roles") || "";
  const user_impersonation_email = appElement?.getAttribute(
    "data-user-impersonation-email"
  );
  const is_user_onboarded = appElement?.getAttribute("data-is-user-onboarded");
  const authenticity_token = appElement?.getAttribute(
    "data-authenticity-token"
  );

  const zoom_authorization_endpoint = appElement?.getAttribute(
    "data-zoom-authorization-endpoint"
  );
  const zoom_admin_authorization_endpoint = appElement?.getAttribute(
    "data-zoom-admin-authorization-endpoint"
  );
  const gong_authorization_endpoint = appElement?.getAttribute(
    "data-gong-authorization-endpoint"
  );
  const microsoft_authorization_endpoint = appElement?.getAttribute(
    "data-microsoft-authorization-endpoint"
  );
  const google_authorization_endpoint = appElement?.getAttribute(
    "data-google-authorization-endpoint"
  );
  const outreach_authorization_endpoint = appElement?.getAttribute(
    "data-outreach-authorization-endpoint"
  );
  const slack_authorization_endpoint = appElement?.getAttribute(
    "data-slack-authorization-endpoint"
  );
  const salesloft_authorization_endpoint = appElement?.getAttribute(
    "data-salesloft-authorization-endpoint"
  );
  const ld_client_side_id = appElement?.getAttribute("data-ld-client-side-id");

  const props = {
    environment,
    user_roles: JSON.parse(user_roles),
    user_impersonation_email,
    is_user_onboarded: is_user_onboarded === "true",
    authenticity_token,
    zoom_authorization_endpoint,
    zoom_admin_authorization_endpoint,
    gong_authorization_endpoint,
    microsoft_authorization_endpoint,
    google_authorization_endpoint,
    slack_authorization_endpoint,
    salesloft_authorization_endpoint,
    outreach_authorization_endpoint,
  };

  if (appElement) {
    const root = createRoot(appElement);
    root.render(
      <LDProvider clientSideID={ld_client_side_id} flags={LDFlags}>
        <ConfigProvider
          theme={{
            token: {
              screenXXLMin: 2000, // for grid (row/col)
              screenXXL: 2000, // default is 1600, for List
            },
          }}
        >
          <Provider store={store}>
            <App {...props} />
          </Provider>
        </ConfigProvider>
      </LDProvider>
    );
  }
});
