import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Tag } from "antd";
import React from "react";

export function CreditsTag({ credits }: { credits: number }) {
  if (credits === 0) return null;
  return (
    <Tag color="gold">
      <Row align="middle" gutter={[8, 0]}>
        <Col>
          <FontAwesomeIcon icon={faCoins} size="xs" />
        </Col>

        <Col>{credits}</Col>
      </Row>
    </Tag>
  );
}
