import React, { useState } from "react";
import { Button, Form, Input, Modal, Select, Switch, message } from "antd";
import { fetchCreateWorkflow, fetchUpdateWorkflow } from "../../../../util/api";
import { useNavigate } from "react-router-dom";
import {
  getFriendlyIntegrationLabel,
  getFriendlyWorkflowTriggerLabel,
} from "../../../../helpers/label_maps";
import { UserRole, WorkflowTriggerType } from "../../../../util/enums";
import { Workflow } from "../../../../util/types";
import {
  useFieldConfigurationGroups,
  useWorkflow,
  useUser,
  useCRMObjects,
} from "../../../../util/data_hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import { teamSelectTagRender } from "../../../shared/teamSelectTagRender";

enum WorkflowAttribute {
  Name = "name",
  Enabled = "enabled",
  TriggerableType = "triggerable_type",
  ProviderNames = "provider_names",
  FieldDefinitionUuids = "field_definition_uuids",
  CrmObjectType = "crm_object_type",
}

interface WorkflowDetailsEditorModalProps {
  open: boolean;
  workflow?: Workflow;
  teamOptions: { label: string; value: string; color: string }[];
  assignedTeamColors: Record<string, string>;
  setOpen: (open: boolean) => void;
}

export function WorkflowDetailsEditorModal({
  open,
  workflow,
  teamOptions,
  assignedTeamColors,
  setOpen,
}: WorkflowDetailsEditorModalProps) {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate } = useWorkflow(workflow?.uuid);
  const { emailWorkflow: emailWorkflowEnabled } = useFlags();

  const { fieldConfigurationGroups } = useFieldConfigurationGroups();
  const { user } = useUser();
  const { objects } = useCRMObjects(user?.organization?.crm_provider_name);
  const trigger = Form.useWatch("triggerable_type", form);

  const [createWorkflowLoading, setCreateWorkflowLoading] =
    useState<boolean>(false);

  const handleCreateWorkflow = async (values) => {
    setCreateWorkflowLoading(true);

    try {
      if (workflow) {
        await fetchUpdateWorkflow(workflow.uuid, values, values.team_uuids);
        message.success(`Workflow saved`);
      } else {
        const newWorkflow = await fetchCreateWorkflow(
          values.team_uuids,
          values
        );
        message.success(`Workflow created`);
        navigate(`${newWorkflow.uuid}/editor`);
      }

      mutate();
      setOpen(false);
    } catch (e) {
      if (e.status >= 500) {
        message.error("An unexpected error occurred. Please try again later.");
      } else {
        message.error(e.response?.data?.message || "An error occurred");
      }
    }

    setCreateWorkflowLoading(false);
  };

  const onCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      footer={null}
      title={workflow ? "Edit Workflow Details" : "Create Workflow"}
      onCancel={onCancel}
    >
      <Form
        id="workflow-details-editor-form"
        form={form}
        layout="vertical"
        onFinish={handleCreateWorkflow}
        initialValues={{
          field_definition_uuids: workflow?.field_definition_triggers.map(
            ({ uuid }) => uuid
          ),
          crm_object_type:
            workflow?.synced_crm_object_triggers.length > 0
              ? workflow?.synced_crm_object_triggers[0].crm_object_type
              : null,
          provider_names: workflow?.triggerable?.provider_names,
          ...workflow,
        }}
      >
        <Form.Item
          label="Name"
          name={WorkflowAttribute.Name}
          style={{ width: "100%" }}
          rules={[{ required: true }]}
        >
          <Input placeholder="My awesome workflow" />
        </Form.Item>

        <Form.Item label={"Teams"} name={"team_uuids"}>
          <Select
            tagRender={teamSelectTagRender(assignedTeamColors)}
            mode="multiple"
            placeholder={"Select Teams"}
            style={{ width: "100%" }}
            options={teamOptions}
            allowClear
          />
        </Form.Item>

        <Form.Item label="Enabled?" name={WorkflowAttribute.Enabled}>
          <Switch />
        </Form.Item>

        <Form.Item
          label="Trigger"
          name={WorkflowAttribute.TriggerableType}
          style={{ width: "100%" }}
          rules={[{ required: true }]}
        >
          <Select
            id="trigger-select"
            placeholder="Select a trigger"
            disabled={!!workflow}
            options={Object.values(WorkflowTriggerType)
              .filter((value) => {
                switch (value) {
                  case WorkflowTriggerType.MessageCreated:
                    return emailWorkflowEnabled ? true : false;
                  case WorkflowTriggerType.MeetingCompleted:
                    return user?.roles?.includes(UserRole.SuperAdmin)
                      ? true
                      : false;
                  default:
                    return true;
                }
              })
              .map((value) => {
                return {
                  value,
                  label: getFriendlyWorkflowTriggerLabel(value),
                };
              })}
          />
        </Form.Item>

        {trigger === WorkflowTriggerType.MeetingCompleted && (
          <Form.Item
            label="Call Providers"
            name={WorkflowAttribute.ProviderNames}
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="label"
              placeholder="Select a provider"
              options={user?.organization?.meeting_providers.map((name) => {
                let label = getFriendlyIntegrationLabel(name);

                const isNative =
                  user?.organization?.native_meeting_transcription_providers?.includes(
                    name
                  );

                if (!isNative) {
                  label += ` (${user?.organization?.non_native_meeting_transcription_credit_cost} Credits)`;
                }

                return { label, value: name };
              })}
            />
          </Form.Item>
        )}

        {trigger === WorkflowTriggerType.CRMRecordUpdated && (
          <Form.Item
            label="CRM Field Triggers"
            name={WorkflowAttribute.FieldDefinitionUuids}
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              mode="multiple"
              optionFilterProp="label"
              placeholder="Select a CRM field"
              options={fieldConfigurationGroups
                .filter(({ synced_crm_object }) => !!synced_crm_object)
                .map(({ label, field_definitions }) => {
                  return {
                    label,
                    options: field_definitions.map(({ uuid, label }) => {
                      return {
                        value: uuid,
                        label,
                      };
                    }),
                  };
                })}
            />
          </Form.Item>
        )}

        {trigger === WorkflowTriggerType.CRMRecordCreated && (
          <Form.Item
            label="CRM Object"
            name={WorkflowAttribute.CrmObjectType}
            style={{ width: "100%" }}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="Select a CRM object"
              options={objects.map(({ crm_object_label, crm_object_name }) => {
                return {
                  label: crm_object_label,
                  value: crm_object_name,
                };
              })}
            />
          </Form.Item>
        )}

        <Button
          htmlType="submit"
          type="primary"
          loading={createWorkflowLoading}
        >
          {workflow ? "Save" : "Create Workflow"}
        </Button>
      </Form>
    </Modal>
  );
}
