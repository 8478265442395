import { Button, Col, Row, Tooltip, Typography } from "antd";
import React, { useCallback, useState } from "react";
import { Editable, RenderElementProps, Slate, withReact } from "slate-react";
import {
  BaseText,
  createEditor,
  Descendant,
  Editor,
  Transforms,
  Text,
  Element,
} from "slate";
import { getFriendlyVariableName } from "../util/helpers";
import { withHistory } from "slate-history";
import { CustomElement, VariableElement } from "../../..";
import { CodeOutlined } from "@ant-design/icons";
import { DynamicVariableModal } from "./DynamicVariableModal";
import { DynamicVariableTag } from "./DynamicVariableTag";

export function VariableElement(props: RenderElementProps) {
  const { variable } = props.element as VariableElement;

  return (
    <span {...props.attributes} contentEditable={false}>
      <DynamicVariableTag variable={variable}>
        {props.children}
      </DynamicVariableTag>
    </span>
  );
}

interface WorkflowTextInputProps {
  value: string;
  placeholder?: string;
  showBorder?: boolean;
  onChange: (value: string) => void;
}

export function WorkflowTextInput({
  value,
  placeholder = "Enter text here...",
  showBorder = true,
  onChange,
}: WorkflowTextInputProps) {
  const [isDynamicVariableModalOpen, setIsDynamicVariableModalOpen] =
    useState<boolean>(false);

  const [editor] = useState(() =>
    withInlines(withHistory(withReact(createEditor())))
  );

  const renderElement = useCallback((props: RenderElementProps) => {
    switch (props.element.type) {
      case "variable":
        return <VariableElement {...props} />;
      default:
        return (
          <Typography.Paragraph {...props.attributes}>
            {props.children}
          </Typography.Paragraph>
        );
    }
  }, []);

  return (
    <>
      <DynamicVariableModal
        open={isDynamicVariableModalOpen}
        setOpen={setIsDynamicVariableModalOpen}
        onInsert={(newValue) => {
          Editor.insertNode(editor, {
            type: "variable",
            variable: newValue,
            children: [{ text: `{{${getFriendlyVariableName(newValue)}}}` }],
          });

          Transforms.select(editor, {
            anchor: Editor.end(editor, []),
            focus: Editor.end(editor, []),
          });

          setIsDynamicVariableModalOpen(false);
        }}
      />

      <Row
        style={
          showBorder
            ? {
                padding: 10,
                border: `1px solid #d9d9d9`,
                borderRadius: 5,
              }
            : null
        }
      >
        <Row style={{ width: "100%" }} justify="space-between" gutter={[0, 8]}>
          <Col style={{ flex: 1 }}>
            <Slate
              editor={editor}
              initialValue={deserialize(value)}
              onValueChange={(value) => onChange(serialize(value))}
            >
              <Editable
                placeholder={placeholder}
                style={{ outline: 0 }}
                renderElement={renderElement}
                onKeyUp={() => {
                  const { anchor } = editor.selection;
                  const { path, offset } = anchor;

                  const [node] = Editor.node(editor, path);
                  const text = (node as { text: string }).text;

                  const startIndex = Math.max(0, offset - 2);

                  if (text.slice(startIndex, offset) == "{{") {
                    Transforms.delete(editor, {
                      at: {
                        anchor: {
                          path: anchor.path,
                          offset: anchor.offset - 2,
                        },
                        focus: anchor,
                      },
                    });

                    setIsDynamicVariableModalOpen(true);
                  }
                }}
              />
            </Slate>
          </Col>

          <Col span={2}>
            <Tooltip title="Insert a dynamic variable">
              <Button
                style={{ width: "100%", padding: 5 }}
                icon={<CodeOutlined />}
                onClick={() => setIsDynamicVariableModalOpen(true)}
              />
            </Tooltip>
          </Col>
        </Row>
      </Row>
    </>
  );
}

const withInlines = (editor) => {
  const { isInline, isElementReadOnly } = editor;

  editor.isInline = (element) =>
    ["variable"].includes(element.type) || isInline(element);

  editor.isElementReadOnly = (element) =>
    element.type === "variable" || isElementReadOnly(element);

  return editor;
};

const deserialize = (string: string | number): Descendant[] => {
  if (!string) return [{ type: "paragraph", children: [{ text: "" }] }];

  if (typeof string !== "string") {
    return [{ type: "paragraph", children: [{ text: string.toString() }] }];
  }

  return string.split("\n").map((line) => {
    const lineElements = line.split(/(\{\{.*?\}\})/g);

    return {
      type: "paragraph",
      children: lineElements.map((line) => {
        const isVariable = line.match(/(\{\{.*?\}\})/g);

        if (isVariable) {
          const friendlyVariableName = getFriendlyVariableName(line);

          return {
            type: "variable",
            variable: line,
            children: [
              {
                text: friendlyVariableName.length
                  ? `{{${friendlyVariableName}}}`
                  : "Invalid variable",
              },
            ],
          };
        } else {
          return { text: line };
        }
      }),
    };
  });
};

const serialize = (value: Descendant[]) => {
  if (!value) return "";

  return value
    .map((node) => {
      if (Element.isElement(node)) {
        return node.children
          .map((node) => {
            if (Text.isText(node)) return (node as BaseText).text;

            const customElementNode = node as CustomElement;
            if (customElementNode.type === "variable") {
              return customElementNode.variable;
            }

            return "";
          })
          .join("");
      } else {
        return { text: "" };
      }
    })
    .join("\n");
};
