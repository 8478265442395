import { useParams } from "react-router-dom";
import { useWorkflowStateContext } from "../shared/WorkflowStateContext";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  useWorkflowActionFieldConfiguration,
  useWorkflowActionFieldConfigurations,
} from "../../../../../util/data_hooks";
import {
  deleteWorkflowActionFieldConfiguration,
  replaceUndefinedValuesWithNull,
  updateWorkflowActionFieldConfiguration,
} from "../../../../../util/api";
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Form,
  message,
  Row,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { WorkflowTextInput } from "../../dynamic_variables/WorkflowTextInput";
import {
  CRMFieldType,
  FieldConfigurationAppendType,
  GenerationModelType,
} from "../../../../../util/enums";
import { WorkflowSelectInput } from "../../dynamic_variables/WorkflowSelectInput";
import {
  getFriendlyAppendTypeLabel,
  getFriendlyGenerationModelDescription,
  getFriendlyGenerationModelTypeLabel,
} from "../../../../../helpers/label_maps";
import { FieldDefinition } from "../../../../../util/types";
import { AppContext } from "../../../../App";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CreditsTag } from "../../../../shared/CreditsTag";
import { debounce } from "lodash";
import { DEBOUNCE_DELAY } from "../../shared/WorkflowStateEditorDrawer";
import {
  faBrain,
  faGlobe,
  faRobot,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MODEL_TYPE_ICON_MAP = {
  [GenerationModelType.Basic]: faRobot,
  [GenerationModelType.Premium]: faWandMagicSparkles,
  [GenerationModelType.BasicWebSearch]: faGlobe,
  [GenerationModelType.Reasoning]: faBrain,
};

enum WorkflowActionFieldConfigurationAttribute {
  Uuid = "uuid",
  FieldDefinition = "field_definition",
  FieldDefinitionUuid = "field_definition_uuid",
  ScreeningEnabled = "screening_enabled",
  ScreeningPrompt = "screening_prompt",
  UserPrompt = "user_prompt",
  AppendType = "append_type",
  ContentToAppend = "content_to_append",
  ModelType = "model_type",
  CreditCost = "credit_cost",
  MissingRequiredFields = "missing_required_fields",

  CreditCostByModelType = "credit_cost_by_model_type",
}

export interface WorkflowActionFieldConfiguration {
  [WorkflowActionFieldConfigurationAttribute.Uuid]: string;
  [WorkflowActionFieldConfigurationAttribute.FieldDefinition]: FieldDefinition;
  [WorkflowActionFieldConfigurationAttribute.FieldDefinitionUuid]: string;
  [WorkflowActionFieldConfigurationAttribute.ScreeningEnabled]: boolean;
  [WorkflowActionFieldConfigurationAttribute.ScreeningPrompt]: string;
  [WorkflowActionFieldConfigurationAttribute.UserPrompt]: string;
  [WorkflowActionFieldConfigurationAttribute.AppendType]: FieldConfigurationAppendType;
  [WorkflowActionFieldConfigurationAttribute.ContentToAppend]: string;
  [WorkflowActionFieldConfigurationAttribute.ModelType]: GenerationModelType;
  [WorkflowActionFieldConfigurationAttribute.CreditCost]: number;
  [WorkflowActionFieldConfigurationAttribute.MissingRequiredFields]: boolean;

  [WorkflowActionFieldConfigurationAttribute.CreditCostByModelType]: {
    [key in GenerationModelType]: number;
  };
}

export function WorkflowActionFieldConfigurationDrawer({
  workflowActionFieldConfigurationUuid,
  onClose,
}: {
  workflowActionFieldConfigurationUuid: string;
  onClose: () => void;
}) {
  const [form] = Form.useForm<WorkflowActionFieldConfiguration>();
  const { workflowUuid } = useParams();

  const { credits } = useFlags();

  const context = useContext(AppContext);
  const isImpersonating = !!context.user_impersonation_email;

  const { workflowState, isSaving, setIsSaving, afterSave } =
    useWorkflowStateContext();

  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const { mutate: mutateWorkflowActionFieldConfigurations } =
    useWorkflowActionFieldConfigurations(workflowUuid, workflowState.uuid);

  const {
    workflowActionFieldConfiguration,
    isLoading: isWorkflowActionFieldConfigurationLoading,
  } = useWorkflowActionFieldConfiguration(
    workflowUuid,
    workflowState.uuid,
    workflowActionFieldConfigurationUuid
  );

  const modelType = Form.useWatch(
    WorkflowActionFieldConfigurationAttribute.ModelType,
    form
  );

  const screeningEnabled = Form.useWatch(
    WorkflowActionFieldConfigurationAttribute.ScreeningEnabled,
    form
  );

  const appendType = Form.useWatch(
    WorkflowActionFieldConfigurationAttribute.AppendType,
    form
  );

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);

        await updateWorkflowActionFieldConfiguration(
          workflowUuid,
          workflowState.uuid,
          workflowActionFieldConfigurationUuid,
          replaceUndefinedValuesWithNull(form.getFieldsValue())
        );

        afterSave();
        mutateWorkflowActionFieldConfigurations();
      } catch (e) {
        message.error(e.message);
      }

      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, afterSave]
  );

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(workflowActionFieldConfiguration);
  }, [workflowActionFieldConfiguration]);

  return (
    <Drawer
      title={
        <Typography.Title level={5} style={{ margin: 0 }}>
          {workflowActionFieldConfiguration.field_definition.label} (
          {
            workflowActionFieldConfiguration.field_definition
              .field_configuration_group.label
          }
          )
        </Typography.Title>
      }
      extra={
        (credits || isImpersonating) && (
          <Col>
            <Select
              value={modelType}
              popupMatchSelectWidth={false}
              onChange={(value) => {
                form.setFieldValue(
                  WorkflowActionFieldConfigurationAttribute.ModelType,
                  value
                );

                debouncedHandleSubmit();
              }}
              style={{ width: "100%" }}
              suffixIcon={null}
              options={Object.entries(
                workflowActionFieldConfiguration.credit_cost_by_model_type || {}
              ).map(([key, value]) => {
                const label = (
                  <Row justify="space-between" gutter={[12, 0]}>
                    <Col>
                      <Space>
                        <FontAwesomeIcon
                          icon={MODEL_TYPE_ICON_MAP[key as GenerationModelType]}
                          size="sm"
                        />

                        <Typography.Text strong>
                          {getFriendlyGenerationModelTypeLabel(
                            key as GenerationModelType
                          )}
                        </Typography.Text>
                      </Space>
                    </Col>

                    <Col>
                      <CreditsTag credits={value} />
                    </Col>
                  </Row>
                );

                return { label, value: key };
              })}
              optionRender={(option) => {
                return (
                  <Space
                    direction="vertical"
                    style={{ width: "100%", padding: "10px 0px" }}
                  >
                    <Typography.Text>{option.label}</Typography.Text>

                    <Typography.Text type="secondary">
                      {getFriendlyGenerationModelDescription(
                        option.value as GenerationModelType
                      )}
                    </Typography.Text>
                  </Space>
                );
              }}
            />
          </Col>
        )
      }
      loading={isWorkflowActionFieldConfigurationLoading}
      open={!!workflowActionFieldConfigurationUuid}
      destroyOnClose
      onClose={onClose}
      width="60rem"
      footer={
        <Space>
          <Button type="primary" loading={isSaving} onClick={onClose}>
            Done
          </Button>

          <Button
            danger
            loading={isRemoving}
            onClick={async () => {
              try {
                setIsRemoving(true);

                await deleteWorkflowActionFieldConfiguration(
                  workflowUuid,
                  workflowState.uuid,
                  workflowActionFieldConfiguration.uuid
                );

                await mutateWorkflowActionFieldConfigurations();
                afterSave();

                message.success("Field Automation Deleted");
              } catch (e) {
                message.error(e.message);
              }

              setIsRemoving(false);
              onClose();
            }}
          >
            Delete
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={debouncedHandleSubmit}
      >
        <Form.Item
          hidden
          name={WorkflowActionFieldConfigurationAttribute.ModelType}
        />

        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Card
              size="small"
              title={
                <Space>
                  <Typography.Text>Screen Field</Typography.Text>

                  <Tooltip title="Learn more">
                    <Typography.Link
                      type="secondary"
                      href={
                        "https://help.swyftai.com/en/articles/9811085-screening-prompts"
                      }
                      target="_blank"
                    >
                      <InfoCircleOutlined />
                    </Typography.Link>
                  </Tooltip>
                </Space>
              }
              extra={
                <Form.Item
                  noStyle
                  name={
                    WorkflowActionFieldConfigurationAttribute.ScreeningEnabled
                  }
                  initialValue={false}
                >
                  <Switch />
                </Form.Item>
              }
            >
              <Row gutter={[12, 12]}>
                <Col span={24}>
                  <Typography.Text type="secondary">
                    Enable screening if this field should only be generated when
                    a specific topic was mentioned or discussed.
                  </Typography.Text>
                </Col>

                <Col span={24}>
                  <Form.Item
                    noStyle
                    hidden={!screeningEnabled}
                    name={
                      WorkflowActionFieldConfigurationAttribute.ScreeningPrompt
                    }
                    initialValue={null}
                  >
                    <WorkflowTextInput
                      placeholder={`Example: "Were next steps discussed in this conversation?"`}
                      value={form.getFieldValue(
                        WorkflowActionFieldConfigurationAttribute.ScreeningPrompt
                      )}
                      onChange={(value) => {
                        form.setFieldValue(
                          WorkflowActionFieldConfigurationAttribute.ScreeningPrompt,
                          value
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>

          <Divider />

          <Col span={24}>
            <Card
              size="small"
              title={
                <Space>
                  <Typography.Text>Prompt</Typography.Text>

                  <Tooltip title="Learn more">
                    <Typography.Link
                      type="secondary"
                      href={
                        "https://help.swyftai.com/en/articles/9821286-field-prompts"
                      }
                      target="_blank"
                    >
                      <InfoCircleOutlined />
                    </Typography.Link>
                  </Tooltip>
                </Space>
              }
            >
              <Form.Item
                name={WorkflowActionFieldConfigurationAttribute.UserPrompt}
                noStyle
              >
                <WorkflowTextInput
                  placeholder={`Example: "What were the next steps discussed? Be brief, format in bullet points."`}
                  value={form.getFieldValue(
                    WorkflowActionFieldConfigurationAttribute.UserPrompt
                  )}
                  onChange={(value) => {
                    form.setFieldValue(
                      WorkflowActionFieldConfigurationAttribute.UserPrompt,
                      value
                    );
                  }}
                />
              </Form.Item>
            </Card>
          </Col>

          {[
            CRMFieldType.Textarea,
            CRMFieldType.String,
            CRMFieldType.EncryptedString,
            CRMFieldType.HTML,
            CRMFieldType.Multipicklist,
          ].includes(
            workflowActionFieldConfiguration.field_definition.field_type
          ) && (
            <Col span={24}>
              <Divider />

              <Card size="small" title="Append Content (Optional)">
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item
                      noStyle
                      name={
                        WorkflowActionFieldConfigurationAttribute.AppendType
                      }
                    >
                      <Select
                        style={{ width: "100%" }}
                        allowClear
                        placeholder="How should this value be appended?"
                        options={
                          workflowActionFieldConfiguration.field_definition
                            .field_type === CRMFieldType.Multipicklist
                            ? [
                                {
                                  label: getFriendlyAppendTypeLabel(
                                    FieldConfigurationAppendType.Merge
                                  ),
                                  value: FieldConfigurationAppendType.Merge,
                                },
                              ]
                            : [
                                {
                                  label: getFriendlyAppendTypeLabel(
                                    FieldConfigurationAppendType.Smart
                                  ),
                                  value: FieldConfigurationAppendType.Smart,
                                },
                                {
                                  label: getFriendlyAppendTypeLabel(
                                    FieldConfigurationAppendType.Top
                                  ),
                                  value: FieldConfigurationAppendType.Top,
                                },
                                {
                                  label: getFriendlyAppendTypeLabel(
                                    FieldConfigurationAppendType.Bottom
                                  ),
                                  value: FieldConfigurationAppendType.Bottom,
                                },
                              ]
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      noStyle
                      hidden={!appendType}
                      name={
                        WorkflowActionFieldConfigurationAttribute.ContentToAppend
                      }
                    >
                      <WorkflowSelectInput
                        placeholder="Select value to append"
                        fieldType={
                          workflowActionFieldConfiguration.field_definition
                            .field_type
                        }
                        value={form.getFieldValue(
                          WorkflowActionFieldConfigurationAttribute.ContentToAppend
                        )}
                        onChange={(value) => {
                          form.setFieldValue(
                            WorkflowActionFieldConfigurationAttribute.ContentToAppend,
                            value
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}

          {[
            CRMFieldType.Picklist,
            CRMFieldType.Multipicklist,
            CRMFieldType.Reference,
          ].includes(
            workflowActionFieldConfiguration.field_definition.field_type
          ) && (
            <Col span={24}>
              <Divider />

              <Card size="small" title="Picklist Options">
                <Typography.Text>
                  {workflowActionFieldConfiguration.field_definition.picklist_definitions
                    .map(({ label }) => label)
                    .join(", ")}
                </Typography.Text>
              </Card>
            </Col>
          )}
        </Row>
      </Form>
    </Drawer>
  );
}
