import React, { useCallback, useState } from "react";
import {
  Button,
  Divider,
  Empty,
  Form,
  message,
  Radio,
  Select,
  Skeleton,
} from "antd";
import {
  useIntegrationUsers,
  useMicrosoftChannels,
  useMicrosoftTeams,
  useSendTeamsMessageAction,
  useUser,
} from "../../../../util/data_hooks";
import {
  CRMFieldType,
  Integration,
  MessageDestinationType,
} from "../../../../util/enums";
import { useIntegrationWindow } from "../../../hooks/useIntegrationWindow";
import { WorkflowTextInput } from "../dynamic_variables/WorkflowTextInput";
import { useParams } from "react-router-dom";
import {
  replaceUndefinedValuesWithNull,
  updateSendTeamsMessageAction,
} from "../../../../util/api";
import { debounce } from "lodash";
import { useWorkflowStateContext } from "./shared/WorkflowStateContext";
import { DEBOUNCE_DELAY } from "../shared/WorkflowStateEditorDrawer";
import { WorkflowSelectInput } from "../dynamic_variables/WorkflowSelectInput";

enum SendTeamsMessageActionAttribute {
  DestinationType = "destination_type",
  TeamId = "team_id",
  ChannelId = "channel_id",
  MessageId = "message_id",
  UserId = "user_id",
  Body = "body",
}

export interface SendTeamsMessageAction {
  [SendTeamsMessageActionAttribute.DestinationType]: MessageDestinationType;
  [SendTeamsMessageActionAttribute.TeamId]: string;
  [SendTeamsMessageActionAttribute.ChannelId]: string;
  [SendTeamsMessageActionAttribute.MessageId]: string;
  [SendTeamsMessageActionAttribute.UserId]: string;
  [SendTeamsMessageActionAttribute.Body]: string;
}

export function SendTeamsMessage() {
  const [form] = Form.useForm<SendTeamsMessageAction>();
  const { workflowUuid } = useParams();

  const [openIntegrationWindow] = useIntegrationWindow();

  const [teamId, setTeamId] = useState<string>(null);

  const { workflowState, setIsSaving, afterSave } = useWorkflowStateContext();

  Form.useWatch((formValues) => {
    const teamId = formValues?.team_id;
    if (teamId) setTeamId(teamId);
  }, form);

  const destinationType = Form.useWatch(
    SendTeamsMessageActionAttribute.DestinationType,
    form
  );

  const { user, isLoading: isUserLoading } = useUser();

  const {
    response: { integration_users },
    isLoading: isUsersLoading,
  } = useIntegrationUsers(Integration.Microsoft);

  const { teams, isLoading: isTeamsLoading } = useMicrosoftTeams();

  const { channels, isLoading: isChannelsLoading } =
    useMicrosoftChannels(teamId);

  const {
    action,
    mutate: mutateAction,
    isLoading: isActionLoading,
  } = useSendTeamsMessageAction(workflowUuid, workflowState.actionable_id);

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);
        await updateSendTeamsMessageAction(
          workflowUuid,
          workflowState.actionable_id,
          replaceUndefinedValuesWithNull(form.getFieldsValue())
        );

        await mutateAction();
        await afterSave();
      } catch (e) {
        message.error("An error occurred. Please try again.");
      }
      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, mutateAction, afterSave]
  );

  const isLoading =
    isUsersLoading ||
    isUserLoading ||
    isTeamsLoading ||
    isChannelsLoading ||
    isActionLoading;

  if (isLoading) return <Skeleton active />;

  if (!user.organization.microsoft_oauth_user) {
    return (
      <Empty description="Microsoft Teams is not connected for your organization">
        <Button
          type="primary"
          onClick={() => openIntegrationWindow(Integration.Microsoft)}
        >
          Connect Microsoft Teams
        </Button>
      </Empty>
    );
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={action}
      onValuesChange={debouncedHandleSubmit}
    >
      <Form.Item
        label="Message Destination"
        name={SendTeamsMessageActionAttribute.DestinationType}
      >
        <Radio.Group
          optionType="button"
          options={[
            {
              label: "Existing Channel",
              value: MessageDestinationType.ExistingChannel,
            },
            {
              label: "Direct Message",
              value: MessageDestinationType.DirectMessage,
            },
            {
              label: "Thread",
              value: MessageDestinationType.MessageThread,
            },
          ]}
        />
      </Form.Item>

      {destinationType === MessageDestinationType.DirectMessage && (
        <Form.Item label="User" name={SendTeamsMessageActionAttribute.UserId}>
          <WorkflowSelectInput
            fieldType={CRMFieldType.Id}
            placeholder="Select User"
            variableDefinitionFilter={(variableDefinitions) => {
              return variableDefinitions.filter((variableDefinition) =>
                variableDefinition.key.includes("microsoft_user_id")
              );
            }}
            options={integration_users.map(({ id, name, email }) => {
              return {
                label: `${name} (${email})`,
                value: id,
              };
            })}
            value={form.getFieldValue(SendTeamsMessageActionAttribute.UserId)}
            onChange={(value) => {
              form.setFieldValue(SendTeamsMessageActionAttribute.UserId, value);
            }}
          />
        </Form.Item>
      )}

      {destinationType === MessageDestinationType.ExistingChannel && (
        <>
          <Form.Item label="Team" name={SendTeamsMessageActionAttribute.TeamId}>
            <Select
              placeholder="Select a team"
              showSearch
              optionFilterProp="label"
              options={teams.map(({ id, name }) => {
                return { label: name, value: id };
              })}
              value={form.getFieldValue(SendTeamsMessageActionAttribute.TeamId)}
              onChange={(value) => {
                form.setFieldValue(
                  SendTeamsMessageActionAttribute.TeamId,
                  value
                );
              }}
            />
          </Form.Item>

          {teamId && (
            <Form.Item
              label="Channel"
              name={SendTeamsMessageActionAttribute.ChannelId}
            >
              <Select
                placeholder="Select a channel"
                showSearch
                optionFilterProp="label"
                options={channels.map(({ id, name }) => {
                  return { label: name, value: id };
                })}
                value={form.getFieldValue(
                  SendTeamsMessageActionAttribute.ChannelId
                )}
                onChange={(value) => {
                  form.setFieldValue(
                    SendTeamsMessageActionAttribute.ChannelId,
                    value
                  );
                }}
              />
            </Form.Item>
          )}
        </>
      )}

      {destinationType === MessageDestinationType.MessageThread && (
        <Form.Item
          label="Message Id"
          name={SendTeamsMessageActionAttribute.MessageId}
        >
          <WorkflowSelectInput
            fieldType={CRMFieldType.Id}
            placeholder="Select Message Id"
            variableDefinitionFilter={(variableDefinitions) => {
              return variableDefinitions.filter((variableDefinition) =>
                variableDefinition.key.includes("microsoft_teams_message_id")
              );
            }}
            value={form.getFieldValue(
              SendTeamsMessageActionAttribute.MessageId
            )}
            onChange={(value) => {
              form.setFieldValue(
                SendTeamsMessageActionAttribute.MessageId,
                value
              );
            }}
          />
        </Form.Item>
      )}

      <Divider />

      <Form.Item
        label="Message Body"
        name={SendTeamsMessageActionAttribute.Body}
      >
        <WorkflowTextInput
          value={form.getFieldValue(SendTeamsMessageActionAttribute.Body)}
          onChange={(value) => {
            form.setFieldValue(SendTeamsMessageActionAttribute.Body, value);
          }}
        />
      </Form.Item>
    </Form>
  );
}
