import React, { useCallback } from "react";
import { Form, message, Skeleton, Switch } from "antd";
import {
  useCreateSlackChannelAction,
  useUser,
} from "../../../../util/data_hooks";
import { WorkflowTextInput } from "../dynamic_variables/WorkflowTextInput";
import { ConnectSlackMessage } from "./shared/ConnectSlackMessage";
import { useParams } from "react-router-dom";
import { SlackUsersSelect } from "./shared/SlackUsersSelect";
import {
  replaceUndefinedValuesWithNull,
  updateCreateSlackChannelAction,
} from "../../../../util/api";
import { debounce } from "lodash";
import { useWorkflowStateContext } from "./shared/WorkflowStateContext";
import { DEBOUNCE_DELAY } from "../shared/WorkflowStateEditorDrawer";

enum CreateSlackChannelActionAttribute {
  ChannelName = "channel_name",
  IsPrivate = "is_private",
  UserIds = "user_ids",
}

export interface CreateSlackChannelAction {
  [CreateSlackChannelActionAttribute.ChannelName]: string;
  [CreateSlackChannelActionAttribute.IsPrivate]: boolean;
  [CreateSlackChannelActionAttribute.UserIds]: string[];
}

export function CreateSlackChannel() {
  const [form] = Form.useForm<CreateSlackChannelAction>();
  const { workflowUuid } = useParams();

  const { workflowState, setIsSaving, afterSave } = useWorkflowStateContext();

  const { user, isLoading: isUserLoading } = useUser();
  const {
    action,
    mutate: mutateAction,
    isLoading: isActionLoading,
  } = useCreateSlackChannelAction(workflowUuid, workflowState.actionable_id);

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);
        await updateCreateSlackChannelAction(
          workflowUuid,
          workflowState.actionable_id,
          replaceUndefinedValuesWithNull(form.getFieldsValue())
        );

        await mutateAction();
        await afterSave();
      } catch (e) {
        message.error("An error occurred. Please try again.");
      }
      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, mutateAction, afterSave]
  );

  const isLoading = isUserLoading || isActionLoading;

  if (isLoading) return <Skeleton active />;
  if (!user.organization.slack_workspace) <ConnectSlackMessage />;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={action}
      onValuesChange={debouncedHandleSubmit}
    >
      <Form.Item
        label="Is Private"
        name={CreateSlackChannelActionAttribute.IsPrivate}
      >
        <Switch defaultValue={false} />
      </Form.Item>

      <Form.Item
        label="Users to invite"
        name={CreateSlackChannelActionAttribute.UserIds}
      >
        <SlackUsersSelect
          value={form.getFieldValue(CreateSlackChannelActionAttribute.UserIds)}
          onChange={(value) => {
            form.setFieldValue(
              CreateSlackChannelActionAttribute.UserIds,
              value
            );
          }}
        />
      </Form.Item>

      <Form.Item
        label="Channel Name"
        name={CreateSlackChannelActionAttribute.ChannelName}
      >
        <WorkflowTextInput
          value={form.getFieldValue(
            CreateSlackChannelActionAttribute.ChannelName
          )}
          onChange={(value) => {
            form.setFieldValue(
              CreateSlackChannelActionAttribute.ChannelName,
              value
            );
          }}
        />
      </Form.Item>
    </Form>
  );
}
