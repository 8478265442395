import React from "react";
import { useSlackChannels } from "../../../../../util/data_hooks";
import SkeletonInput from "antd/es/skeleton/Input";
import { CRMFieldType } from "../../../../../util/enums";
import { WorkflowSelectInput } from "../../dynamic_variables/WorkflowSelectInput";

export function SlackChannelsSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const { channels, isLoading } = useSlackChannels();

  if (isLoading) return <SkeletonInput active style={{ width: 500 }} />;

  return (
    <WorkflowSelectInput
      fieldType={CRMFieldType.Id}
      placeholder="Select Channel"
      variableDefinitionFilter={(variableDefinitions) => {
        return variableDefinitions.filter((variableDefinition) =>
          variableDefinition.key.includes("slack_channel_id")
        );
      }}
      options={channels.map(({ channel_id, channel_name, is_private }) => {
        return {
          label: `#${channel_name}${is_private ? " (Private)" : ""}`,
          value: channel_id,
        };
      })}
      value={value}
      onChange={onChange}
    />
  );
}
