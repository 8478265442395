import React, { useState } from "react";
import { Button, Col, Row, Select, Tooltip } from "antd";
import { CodeOutlined } from "@ant-design/icons";
import { DynamicVariableModal } from "./DynamicVariableModal";
import { parseVariable } from "../util/helpers";
import { CRMFieldType } from "../../../../util/enums";
import { VariableDefinition } from "../../../../util/types";
import { DynamicVariableTag } from "./DynamicVariableTag";

interface WorkflowSelectInputProps {
  options?: { label: string; value: string }[];
  placeholder: string;
  value: string | string[];
  multiple?: boolean;
  fieldType?: CRMFieldType;
  variableDefinitionFilter?: (
    variableDefinitions: VariableDefinition[]
  ) => VariableDefinition[];
  onChange: (value: string | string[]) => void;
}

export function WorkflowSelectInput({
  options = [],
  placeholder,
  value,
  multiple = false,
  fieldType,
  variableDefinitionFilter,
  onChange,
}: WorkflowSelectInputProps) {
  const [variableModalOpen, setVariableModalOpen] = useState<boolean>(false);

  return (
    <>
      <DynamicVariableModal
        value={value as string}
        fieldType={fieldType}
        allowFormat={false}
        open={variableModalOpen}
        setOpen={setVariableModalOpen}
        onInsert={(newValue) => {
          onChange(
            multiple ? [...new Set([...(value || []), newValue])] : newValue
          );
          setVariableModalOpen(false);
        }}
        variableDefinitionFilter={variableDefinitionFilter}
      />

      <Row gutter={[8, 8]}>
        <Col span={22}>
          <Select
            style={{ width: "100%" }}
            mode={multiple ? "multiple" : null}
            showSearch
            allowClear
            optionFilterProp="label"
            placeholder={placeholder}
            options={options}
            labelRender={({ label, value }) => {
              const variableConfiguration = parseVariable(value as string);

              if (variableConfiguration.variableType) {
                return <DynamicVariableTag variable={value as string} />;
              } else {
                return label;
              }
            }}
            dropdownStyle={{ display: options.length === 0 ? "none" : null }}
            dropdownRender={(menu) => {
              return <>{menu}</>;
            }}
            value={value}
            onChange={onChange}
            onClick={() => options.length === 0 && setVariableModalOpen(true)}
          />
        </Col>

        <Col span={2}>
          <Tooltip title="Insert a dynamic variable">
            <Button
              onClick={() => setVariableModalOpen(true)}
              style={{ width: "100%", padding: 5 }}
              icon={<CodeOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
    </>
  );
}
