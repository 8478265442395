import { Skeleton, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import { getFormattedDate } from "../../../helpers/date_helpers";
import { useWorkflowInstances } from "../../../util/data_hooks";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { WorkflowStatus } from "../../../util/enums";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import { blue, green, red } from "@ant-design/colors";
import { WorkflowInstance } from "../../../util/types";
import { CreditsTag } from "../../shared/CreditsTag";
import { useFlags } from "launchdarkly-react-client-sdk";

export function WorkflowInstances() {
  const navigate = useNavigate();
  const { workflowUuid } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { workflowInstances, isLoading, meta } = useWorkflowInstances(
    workflowUuid,
    currentPage - 1,
    pageSize
  );

  const { credits } = useFlags();

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <Outlet />

      <Typography.Title level={4}>
        Workflow Runs ({meta?.total})
      </Typography.Title>

      <Table<WorkflowInstance>
        style={{ paddingBottom: 150 }}
        rowKey={({ uuid }) => uuid}
        dataSource={workflowInstances}
        onRow={({ uuid }) => {
          return {
            onClick: () => navigate(`workflow-instances/${uuid}`),
            style: { cursor: "pointer" },
          };
        }}
        pagination={{
          current: currentPage,
          pageSize,
          total: meta?.total || 0,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        columns={[
          {
            title: "User",
            dataIndex: "user_email",
          },
          {
            title: "Triggered at",
            dataIndex: "created_at",
            render: (createdAt) => getFormattedDate(createdAt),
          },

          ...(credits
            ? [
                {
                  title: "Credits",
                  dataIndex: "total_credit_cost",
                  align: "center" as const,
                  width: 150,
                  render: (totalCreditCost) =>
                    totalCreditCost > 0 ? (
                      <CreditsTag credits={totalCreditCost} />
                    ) : (
                      "-"
                    ),
                },
              ]
            : []),
          {
            title: "Status",
            dataIndex: "status",
            width: 250,
            align: "center",
            render: (workflowStatus: WorkflowStatus) => {
              switch (workflowStatus) {
                case (WorkflowStatus.Triggered, WorkflowStatus.InProgress): {
                  return (
                    <Space>
                      <LoadingOutlined style={{ color: blue.primary }} spin />
                      <Typography.Text>In Progress...</Typography.Text>
                    </Space>
                  );
                }
                case WorkflowStatus.Complete: {
                  return (
                    <Space>
                      <CheckCircleTwoTone twoToneColor={green.primary} />
                      <Typography.Text>Complete</Typography.Text>
                    </Space>
                  );
                }
                case WorkflowStatus.Error: {
                  return (
                    <Space>
                      <CloseCircleTwoTone twoToneColor={red.primary} />
                      <Typography.Text>Error</Typography.Text>
                    </Space>
                  );
                }
                default: {
                  return "-";
                }
              }
            },
          },
        ]}
      />
    </>
  );
}
