import React, { createContext, useContext } from "react";
import { WorkflowState } from "../../../../../util/types";

interface WorkflowStateContextType {
  isSaving: boolean;
  setIsSaving: (saving: boolean) => void;
  workflowState: WorkflowState;
  afterSave: () => Promise<void>;
}

interface WorkflowStateProviderProps {
  children: React.ReactNode;
  workflowState: WorkflowState;
  isSaving: boolean;
  setIsSaving: (saving: boolean) => void;
  afterSave: () => Promise<void>;
}

const WorkflowStateContext = createContext<
  WorkflowStateContextType | undefined
>(undefined);

export function WorkflowStateProvider({
  children,
  workflowState,
  isSaving,
  setIsSaving,
  afterSave,
}: WorkflowStateProviderProps) {
  return (
    <WorkflowStateContext.Provider
      value={{
        isSaving,
        setIsSaving,
        workflowState,
        afterSave,
      }}
    >
      {children}
    </WorkflowStateContext.Provider>
  );
}

export function useWorkflowStateContext() {
  const context = useContext(WorkflowStateContext);
  if (context === undefined) {
    throw new Error(
      "useWorkflowState must be used within a WorkflowStateProvider"
    );
  }
  return context;
}
