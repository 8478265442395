import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface WorkflowFilterSlice {
  teamFilterUuids: string[];
}

const initialState: WorkflowFilterSlice = {
  teamFilterUuids: [],
};

export const workflowFilterSlice = createSlice({
  name: "workflowFilter",
  initialState,
  reducers: {
    setTeamFilterUuids: (state, action: PayloadAction<string[]>) => {
      state.teamFilterUuids = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTeamFilterUuids } = workflowFilterSlice.actions;

export default workflowFilterSlice.reducer;
