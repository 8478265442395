import { useParams } from "react-router-dom";
import { useUpstreamWorkflowStates } from "../../../../util/data_hooks";
import { CRMFieldType } from "../../../../util/enums";
import { parseVariable, WorkflowVariableType } from "./helpers";
import { VariableDefinition } from "../../../../util/types";

export function useWorkflowVariableDefinition(variable: string): {
  variableDefinition: Partial<VariableDefinition>;
  isLoading: boolean;
} {
  const { workflowUuid, workflowStateUuid } = useParams();
  const { variableType, attributes } = parseVariable(variable);

  const { workflowStates: upstreamWorkflowStates, isLoading } =
    useUpstreamWorkflowStates(workflowUuid, workflowStateUuid, {
      includeCurrent: window.location.pathname.includes(
        "/workflow-transitions"
      ),
      includeVariableDefinitions: true,
    });

  if (variableType === WorkflowVariableType.Datetime) {
    return {
      isLoading: isLoading,
      variableDefinition: {
        type: CRMFieldType.DateTime,
        description: "Date/time relative to when the workflow is triggered.",
      },
    };
  } else if (variableType === WorkflowVariableType.Action) {
    const variableDefinition = upstreamWorkflowStates
      .flatMap(({ variable_definitions }) => variable_definitions)
      .find(
        ({ workflow_state_uuid, key }) =>
          workflow_state_uuid === attributes[0] &&
          key === attributes.slice(1).join(".")
      );

    return {
      isLoading: isLoading,
      variableDefinition: variableDefinition,
    };
  }

  return { isLoading, variableDefinition: null };
}
