import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Integration, WorkflowActionType } from "../../util/enums";
import { faSlack, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  faWandMagicSparkles,
  faHashtag,
  faFileCirclePlus,
  faFilePen,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { gold } from "@ant-design/colors";
import { IntegrationLogo } from "./IntegrationLogo";
import { Space } from "antd";

export function WorkflowActionIcon({
  actionType,
  integrations = [],
  size = "3x",
}: {
  actionType: WorkflowActionType;
  integrations?: Integration[];
  size?: SizeProp;
}) {
  if (integrations.length > 0) {
    return (
      <Space>
        {integrations.map((integration) => (
          <IntegrationLogo type={integration} key={integration} width={18} />
        ))}
      </Space>
    );
  }
  switch (actionType) {
    case WorkflowActionType.Trigger:
      return <FontAwesomeIcon icon={faBolt} size={size} color={gold[5]} />;
    case WorkflowActionType.CreateCRMRecord:
      return (
        <FontAwesomeIcon icon={faFileCirclePlus} size={size} color="#2A2E4B" />
      );
    case WorkflowActionType.UpdateCRMRecord:
      return <FontAwesomeIcon icon={faFilePen} size={size} color="#2A2E4B" />;
    case WorkflowActionType.GenerateContent:
      return (
        <FontAwesomeIcon
          icon={faWandMagicSparkles}
          size={size}
          color="#2A2E4B"
        />
      );
    case WorkflowActionType.CreateSlackChannel:
      return <FontAwesomeIcon icon={faHashtag} size={size} color="#2A2E4B" />;
    case WorkflowActionType.SendSlackMessage:
      return (
        <FontAwesomeIcon
          icon={faSlack as IconProp}
          size={size}
          color="#2A2E4B"
        />
      );
    case WorkflowActionType.SendTeamsMessage:
      return (
        <FontAwesomeIcon
          icon={faMicrosoft as IconProp}
          size={size}
          color="#2A2E4B"
        />
      );
  }
}
