import React, { useCallback } from "react";
import { Divider, Form, message, Radio, Skeleton, Typography } from "antd";
import {
  useSendSlackMessageAction,
  useUser,
} from "../../../../util/data_hooks";
import { CRMFieldType, MessageDestinationType } from "../../../../util/enums";
import { SlackUsersSelect } from "./shared/SlackUsersSelect";
import { WorkflowTextInput } from "../dynamic_variables/WorkflowTextInput";
import { useParams } from "react-router-dom";
import {
  replaceUndefinedValuesWithNull,
  updateSendSlackMessageAction,
} from "../../../../util/api";
import { SlackChannelsSelect } from "./shared/SlackChannelSelect";
import { ConnectSlackMessage } from "./shared/ConnectSlackMessage";
import { debounce } from "lodash";
import { useWorkflowStateContext } from "./shared/WorkflowStateContext";
import { DEBOUNCE_DELAY } from "../shared/WorkflowStateEditorDrawer";
import { WorkflowSelectInput } from "../dynamic_variables/WorkflowSelectInput";
enum SendSlackMessageActionAttribute {
  DestinationType = "destination_type",
  ChannelId = "channel_id",
  MessageId = "message_id",
  UserIds = "user_ids",
  Body = "body",
}

export interface SendSlackMessageAction {
  [SendSlackMessageActionAttribute.DestinationType]: MessageDestinationType;
  [SendSlackMessageActionAttribute.ChannelId]: string;
  [SendSlackMessageActionAttribute.MessageId]: string;
  [SendSlackMessageActionAttribute.UserIds]: string[];
  [SendSlackMessageActionAttribute.Body]: string;
}

export function SendSlackMessage() {
  const [form] = Form.useForm<SendSlackMessageAction>();
  const { workflowUuid } = useParams();

  const { workflowState, setIsSaving, afterSave } = useWorkflowStateContext();

  const { user, isLoading: isUserLoading } = useUser();
  const {
    action,
    mutate: mutateAction,
    isLoading: isActionLoading,
  } = useSendSlackMessageAction(workflowUuid, workflowState.actionable_id);

  const isLoading = isUserLoading || isActionLoading;

  const destinationType = Form.useWatch(
    SendSlackMessageActionAttribute.DestinationType,
    form
  );

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);
        await updateSendSlackMessageAction(
          workflowUuid,
          workflowState.actionable_id,
          replaceUndefinedValuesWithNull(form.getFieldsValue())
        );

        await mutateAction();
        await afterSave();
      } catch (e) {
        message.error("An error occurred. Please try again.");
      }
      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, mutateAction, afterSave]
  );

  if (isLoading) return <Skeleton active />;
  if (!user.organization.slack_workspace) return <ConnectSlackMessage />;

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Select Message Destination
        </Typography.Title>

        <Typography.Text type="secondary">
          Where should the Slack message be sent?
        </Typography.Text>
      </div>

      <Form
        form={form}
        layout="vertical"
        initialValues={action}
        onValuesChange={debouncedHandleSubmit}
      >
        <Form.Item
          label="Destination"
          name={SendSlackMessageActionAttribute.DestinationType}
        >
          <Radio.Group
            optionType="button"
            options={[
              {
                label: "Existing Channel",
                value: MessageDestinationType.ExistingChannel,
              },
              {
                label: "Direct Message",
                value: MessageDestinationType.DirectMessage,
              },
              {
                label: "Thread",
                value: MessageDestinationType.MessageThread,
              },
            ]}
          />
        </Form.Item>

        {destinationType === MessageDestinationType.ExistingChannel && (
          <>
            <Form.Item
              label="Channel Name"
              name={SendSlackMessageActionAttribute.ChannelId}
            >
              <SlackChannelsSelect
                value={form.getFieldValue(
                  SendSlackMessageActionAttribute.ChannelId
                )}
                onChange={(value) => {
                  form.setFieldValue(
                    SendSlackMessageActionAttribute.ChannelId,
                    value
                  );
                }}
              />
            </Form.Item>

            <Form.Item
              label="Users to invite"
              name={SendSlackMessageActionAttribute.UserIds}
            >
              <SlackUsersSelect
                value={form.getFieldValue(
                  SendSlackMessageActionAttribute.UserIds
                )}
                onChange={(value) => {
                  form.setFieldValue(
                    SendSlackMessageActionAttribute.UserIds,
                    value
                  );
                }}
              />
            </Form.Item>
          </>
        )}

        {destinationType === MessageDestinationType.DirectMessage && (
          <Form.Item
            label="Users"
            name={SendSlackMessageActionAttribute.UserIds}
          >
            <SlackUsersSelect
              value={form.getFieldValue(
                SendSlackMessageActionAttribute.UserIds
              )}
              onChange={(value) => {
                form.setFieldValue(
                  SendSlackMessageActionAttribute.UserIds,
                  value
                );
              }}
            />
          </Form.Item>
        )}

        {destinationType === MessageDestinationType.MessageThread && (
          <>
            <Form.Item
              label="Channel Name"
              name={SendSlackMessageActionAttribute.ChannelId}
            >
              <SlackChannelsSelect
                value={form.getFieldValue(
                  SendSlackMessageActionAttribute.ChannelId
                )}
                onChange={(value) => {
                  form.setFieldValue(
                    SendSlackMessageActionAttribute.ChannelId,
                    value
                  );
                }}
              />
            </Form.Item>

            <Form.Item
              label="Message"
              name={SendSlackMessageActionAttribute.MessageId}
            >
              <WorkflowSelectInput
                fieldType={CRMFieldType.Id}
                placeholder="Select Message"
                variableDefinitionFilter={(variableDefinitions) => {
                  return variableDefinitions.filter((variableDefinition) =>
                    variableDefinition.key.includes("slack_message_id")
                  );
                }}
                value={form.getFieldValue(
                  SendSlackMessageActionAttribute.MessageId
                )}
                onChange={(value) => {
                  form.setFieldValue(
                    SendSlackMessageActionAttribute.MessageId,
                    value
                  );
                }}
              />
            </Form.Item>
          </>
        )}

        <Divider />

        <Form.Item
          label="Message Body"
          name={SendSlackMessageActionAttribute.Body}
        >
          <WorkflowTextInput
            value={form.getFieldValue(SendSlackMessageActionAttribute.Body)}
            onChange={(value) => {
              form.setFieldValue(SendSlackMessageActionAttribute.Body, value);
            }}
          />
        </Form.Item>
      </Form>
    </>
  );
}
